import Hls from "hls.js";
import * as THREE from "three";
import { ensureFirebaseAuth, getDownloadUrl } from "../../db";

export function addVideoElement(loop = true) {
  const videoElement = document.createElement("video");
  videoElement.setAttribute("style", "display: none");

  if (loop) videoElement.setAttribute("loop", true);

  document.body.appendChild(videoElement);

  return videoElement;
}

export function createVideoTexture(videoElement, offset = null, scale = null) {
  const videoTexture = new THREE.VideoTexture(videoElement);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;
  videoTexture.format = THREE.RGBFormat;
  if (offset) {
    videoTexture.offset = new THREE.Vector2(offset.x, offset.y);
  }
  if (scale) {
    videoTexture.repeat.set(scale.x, scale.y);
  }

  // console.log("repeast", videoTexture.repeat, scale);
  return videoTexture;
}

export function loadMuxStream(videoElement, src) {
  // Let native HLS support handle it if possible
  if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
    videoElement.src = src;
  } else if (Hls.isSupported()) {
    // HLS.js-specific setup code
    const hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(videoElement);
  }
}

export function unloadStream(videoElement) {
  if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
    videoElement.src = null;
  } else if (Hls.isSupported()) {
    // HLS.js-specific setup code
    const hls = new Hls();
    hls.detachMedia(videoElement);
  }
}

export function muxUrl(playbackId) {
  return `https://stream.mux.com/${playbackId}.m3u8`;
}

function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

function createSource(path, sourceType) {
  const source = document.createElement("source");
  source.setAttribute("src", path);
  source.setAttribute("type", sourceType);

  return source;
}

export function loadStoredVideo(videoElement, { mp4 }) {
  removeAllChildNodes(videoElement);

  videoElement.setAttribute("crossOrigin", "anonymous");
  videoElement.setAttribute("preload", "none");

  videoElement.appendChild(createSource(mp4, "video/mp4"));
}

export const cropToOffsetAndScale = ([
  cropStartX,
  cropStartY,
  cropEndX,
  cropEndY,
]) => {
  const offset = {
    x: cropStartX,
    y: cropStartY,
  };

  const scale = {
    x: cropEndX - cropStartX,
    y: cropEndY - cropStartY,
  };

  // console.log("offset and scale", offset, scale);

  return {
    offset,
    scale,
  };
};

export const cropToFaceUv = (
  faces,
  [cropStartX, cropStartY, cropEndX, cropEndY]
) => {
  return faces.map((face) =>
    face.map((faceTriangles) =>
      faceTriangles.map((triangle) => {
        const x = triangle.x === 0 ? cropStartX : cropEndX;
        const y = triangle.y === 0 ? cropStartY : cropEndY;
        // console.log("x, y", triangle.x, triangle.y, x, y);
        return new THREE.Vector2(x, y);
      })
    )
  );
};

export const buildPlaySurfaces = (videoElement, pivot, surfacesConfig) => {
  const playSurfaces = [];
  const videoTextures = [];
  // console.log("number surfaces", Object.values(surfacesConfig).length);
  for (let {
    shape,
    position,
    rotation,
    crop,
    scale: screenScale = 1,
  } of Object.values(surfacesConfig)) {
    const { offset, scale } = cropToOffsetAndScale(crop);
    // console.log("offset and scale", offset, scale);
    const videoTexture = createVideoTexture(videoElement, offset, scale);
    const material = new THREE.MeshBasicMaterial({
      map: videoTexture,
    });

    const geometry = new THREE.BoxGeometry(
      shape[0],
      shape[1] * screenScale,
      shape[2] * screenScale
    );
    // const geometry = new THREE.BoxGeometry(10, 10, 10);
    const playSurface = new THREE.Mesh(geometry, material);

    playSurface.translateX(position[0]);
    playSurface.translateY(position[1]);
    playSurface.translateZ(position[2]);

    if (rotation) {
      playSurface.rotateX(rotation[0]);
      playSurface.rotateY(rotation[1]);
      playSurface.rotateZ(rotation[2]);
    }

    playSurfaces.push(playSurface);
    videoTextures.push(videoTexture);

    pivot.add(playSurface);
  }

  return { playSurfaces, videoTextures };
};

export const shapeFromCrop = (crop, width, height) => {
  const [cropLeft, cropBottom, cropRight, cropTop] = crop;
  const [cropWidth, cropHeight] = [cropRight - cropLeft, cropTop - cropBottom];

  return [0.01, height * cropHeight, width * cropWidth];
};

export function loadVideoPaths(videoFolder) {
  ensureFirebaseAuth();

  const mp4 = getDownloadUrl(`${videoFolder}/video.mp4`);

  return {
    mp4,
  };
}

function getSeekTime(videoDuration, currentTimeMs) {
  const timeInSeconds = Math.floor(currentTimeMs / 1000);

  return timeInSeconds % videoDuration;
}

export function seekVideoToSyncedPosition(videoElement, currentTimeMs) {
  if (!videoElement.duration) return;
  const seekPosition = getSeekTime(videoElement.duration, currentTimeMs);

  videoElement.currentTime = seekPosition;
}
