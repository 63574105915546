/* eslint-disable guard-for-in */
// import Client from "./client";
import { db, ensureFirebaseAuth } from "./db";
const EventEmitter = require("events");

const DISTANCE_THRESHOLD_SQUARED = 500;

class GameState extends EventEmitter {
  constructor() {
    super();
    this.clients = {};
    this.lastUpdateTime = Date.now();
    this.playerState = {
      position: [0, 1000, 0], // start in space
      rotation: [0, 0, 0, 1],
    };
    this.currentSubscriptions = [];

    this.maxPeers = 12; // set initial value
  }

  async setMaxPeersFromDB() {
    await ensureFirebaseAuth();

    db.collection("mediasoupSettings")
      .doc("settings")
      .onSnapshot((doc) => {
        const { maxPeers } = doc.data();
        console.log(`Updating maximum Mediasoup peers to ${maxPeers}`);
        this.maxPeers = maxPeers;
      });
  }

  // addClient(id) {
  //   if (!(id in this.clients)) {
  //     console.log(`Adding client with id ${id}`);
  //     this.clients[id] = new Client(id);
  //     this.emit("add-client");
  //   }
  // }

  // removeClient(id) {
  //   console.log(`Removing client with id ${id}`);
  //   this.clients[id].remove();
  //   delete this.clients[id];
  // }

  // updateGameState(stats) {
  //   // first remove clients that no longer exist
  //   for (const id in this.clients) {
  //     if (!(id in stats)) {
  //       this.removeClient(id);
  //     }
  //   }

  //   for (const id in stats) {
  //     if (!(id in this.clients)) {
  //       this.addClient(id);
  //     }
  //     this.clients[id].setPosition(stats[id].position);
  //     this.clients[id].setRotation(stats[id].rotation);
  //   }
  // }

  // updateClientSizes(shrinkZones) {
  //   Object.values(this.clients).forEach((client) => {
  //     const scale = shrinkZones.getScale(client.group.position);

  //     client.setScale(scale);
  //   });
  // }

  setPlayerState(playerState) {
    this.lastUpdateTime = Date.now();
    this.playerState = playerState;
    // localStorage.setItem("playerTS", Date.now());
    // localStorage.setItem("playerX", this.playerState.position[0]);
    // localStorage.setItem("playerZ", this.playerState.position[2]);

    // look at uses a point in space
    // we are storing player rotation as a direction
    // add that direction to the current position and we should get a point in space
    // localStorage.setItem(
    //   "lookAtX",
    //   this.playerState.position[0] + this.playerState.rotation[0]
    // );
    // localStorage.setItem(
    //   "lookAtZ",
    //   this.playerState.position[2] + this.playerState.rotation[2]
    // );
  }

  getPlayerState() {
    return this.playerState;
  }

  get playerPosition() {
    return this.playerState.position;
  }

  // updateClients() {
  //   for (const _id in this.clients) {
  //     if (_id in this.clients) {
  //       this.clients[_id].update();
  //     }
  //   }
  // }

  // updateClientAudio(silentZones) {
  //   for (const _id in this.clients) {
  //     if (_id in this.clients) {
  //       this.clients[_id].updateAudio(silentZones);
  //     }
  //   }
  // }

  // getClosestPeers() {
  //   const closestClients = [];
  //   for (const _id in this.clients) {
  //     if (this.clients[_id].closeEnoughToConnect()) {
  //       closestClients.push(_id);
  //     }
  //   }
  //   return closestClients;
  // }

  // getAllClients() {
  //   return this.clients;
  // }

  // selectivelyPauseAndResumeConsumers() {
  //   for (const _id in this.clients) {
  //     if (this.clients[_id].closeEnoughToConnect()) {
  //       // resumeAllConsumersForPeer(_id);
  //       this.emit("resume-all-consumers", _id);
  //     } else {
  //       // pauseAllConsumersForPeer(_id);
  //       this.emit("pause-all-consumers", _id);
  //     }
  //   }
  // }

  // getClientPositions() {
  //   const positions = [];
  //   // eslint-disable-next-line guard-for-in
  //   for (const id in this.clients) {
  //     positions.push(this.clients[id].group.position);
  //   }
  //   return positions;
  // }

  // attachClientsToScene(scene) {
  //   // eslint-disable-next-line guard-for-in
  //   for (const id in this.clients) {
  //     this.clients[id].attachToScene(scene);
  //   }
  // }

  // updateCurrentSubscriptions() {
  //   // for each client, get their distance from the player
  //   // remove all clients outside of a threshold value
  //   // sort the remaining clients by distance
  //   // return the nearest X clients
  //   const closestClients = [];
  //   const clientsAndDistances = [];

  //   for (const _id in this.clients) {
  //     const distanceSquaredFromPlayer = this.clients[
  //       _id
  //     ].getDistanceSquaredToCamera();
  //     if (distanceSquaredFromPlayer <= DISTANCE_THRESHOLD_SQUARED) {
  //       clientsAndDistances.push({
  //         id: _id,
  //         distanceSquared: distanceSquaredFromPlayer,
  //       });
  //     }
  //   }

  //   // sort clients by distance
  //   clientsAndDistances.sort((a, b) => {
  //     return a.distanceSquared - b.distanceSquared;
  //   });

  //   // get the first up-to-this.maxPeers ## of peers:
  //   for (let i = 0; i < clientsAndDistances.length && i < this.maxPeers; i++) {
  //     closestClients.push(clientsAndDistances[i].id);
  //   }

  //   this.currentSubscriptions = closestClients;
  // }

  // getCurrentSubscriptions() {
  //   return this.currentSubscriptions;
  // }
}

const gameState = new GameState();

export { gameState };
