import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCZLH0d5YC31lniQi-6hSlQnAMJ8tfn7oQ",
  authDomain: "dans-performance-space.firebaseapp.com",
  databaseURL: "https://dans-performance-space.firebaseio.com",
  projectId: "dans-performance-space",
  storageBucket: "dans-performance-space.appspot.com",
  messagingSenderId: "506424355828",
  appId: "1:506424355828:web:b0c603552798941554e7a1",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
// export const storage = firebase.storage();

let authenticated = false;

export async function ensureFirebaseAuth() {
  if (!authenticated) {
    await firebase.auth().signInAnonymously();
    authenticated = true;
  }
}

const FILE_DOMAIN = "https://www.yorb.pw";

export function getDownloadUrl(filePath) {
  return `${FILE_DOMAIN}${filePath}`;
}
