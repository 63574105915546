import * as THREE from "three";

const MOTION_LERP = 0.05;

class SoundMesh {
  constructor(pivot, lightColor = 0x00ffff, color = 0x00ffff) {
    this.pivot = pivot;
    this.lightColor = lightColor;
    this.color = color;

    this.setup();
  }

  setup() {
    const geometry = new THREE.SphereBufferGeometry(0.1, 32, 32);
    const bulbLight = new THREE.PointLight(this.lightColor, 1, 20, 4);

    const bulbMaterial = new THREE.MeshStandardMaterial({
      emissive: this.color,
      emissiveIntensity: 1,
      transparent: true,
      opacity: 0.7,
      color: this.color,
    });

    const mesh = new THREE.Mesh(geometry, bulbMaterial);

    bulbLight.add(mesh);
    bulbLight.castShadow = true;

    this.material = bulbMaterial;
    this.mesh = mesh;
    this.geometry = geometry;
    this.bulbLight = bulbLight;

    this.pivot.add(bulbLight);
  }

  setScale(newScale) {
    this.mesh.scale.set(newScale, newScale, newScale);
  }

  setIntensity(materialIntensity) {
    this.material.emissiveIntensity = materialIntensity;
    this.bulbLight.intensity = materialIntensity;
  }

  setColor(newColor) {
    // this.material.emissive.color.setColor(newColor);
    const hex = newColor.getHex();
    this.material.emissive.setHex(hex);
    this.material.color.setHex(hex);
    this.bulbLight.color.setHex(hex);
  }

  setOpacity(opacity) {
    this.material.opacity = opacity;
  }

  moveTo(position, lerpSpeed) {
    this.bulbLight.position.lerp(position, lerpSpeed);
  }

  add(element) {
    this.bulbLight.add(element);
  }

  remove() {
    this.pivot.remove(this.bulbLight);
    // this.bulbLight.dispose();
    this.material.dispose();
    this.geometry.dispose();
    // this.mesh.dispose();
  }
}

const buildMeshes = (pivot) => {
  const leftMesh = new SoundMesh(pivot);
  const rightMesh = new SoundMesh(pivot);

  return { leftMesh, rightMesh };
};

const createPositionalAudio = (
  buffer,
  listener,
  { refDistance, rollOffFactor, maxDistance, distanceModel }
) => {
  const positional = new THREE.PositionalAudio(listener);

  positional.setNodeSource(buffer);

  // console.log("params", refDistance, rollOffFactor, maxDistance, distanceModel);

  positional.setRefDistance(refDistance);
  positional.setRolloffFactor(rollOffFactor);

  if (maxDistance) {
    positional.setMaxDistance(maxDistance);
  }

  if (distanceModel) {
    positional.setDistanceModel(distanceModel);
  }

  return positional;
};

const buildSounds = (listener, video, leftMesh, rightMesh, soundSettings) => {
  const audioCtx = listener.context;
  const splitter = audioCtx.createChannelSplitter(2);

  const audioSource = audioCtx.createMediaElementSource(video);
  audioSource.connect(splitter);

  const leftBuffer = audioCtx.createChannelMerger(1);
  splitter.connect(leftBuffer, 0);

  const rightBuffer = audioCtx.createChannelMerger(1);
  splitter.connect(rightBuffer, 1);

  // create the PositionalAudio object (passing in the listener)
  const leftSound = createPositionalAudio(leftBuffer, listener, soundSettings);
  const rightSound = createPositionalAudio(
    rightBuffer,
    listener,
    soundSettings
  );

  leftMesh.add(leftSound);
  rightMesh.add(rightSound);

  return { leftSound, rightSound };
};

function getTargetIntensity(volume, pulseLevel, lpgTrigger) {
  const intensityBlend = lpgTrigger * pulseLevel + 1 - pulseLevel;

  return Math.min(volume * 1.5, intensityBlend);
}

const nonModulatedColor = new THREE.Color(0x0000ff);
const modulatedColor = new THREE.Color(0xffffff);

export default class SoundObjects {
  constructor(pivot, camera, video, config) {
    this.pivot = pivot;
    this.camera = camera;
    this.config = config;
    this.playArea = this.config.playArea.split(",").map((x) => +x);
    this.playAreaCenter = this.config.playAreaCenter.split(",").map((x) => +x);
    this.video = video;

    this.leftTarget = new THREE.Vector3(0, 0, 0);
    this.rightTarget = new THREE.Vector3(0, 0, 0);

    this.leftSize = 0.1;
    this.rightSize = 0.1;
  }

  play(listener) {
    if (!this.leftMesh && !this.rightMesh) {
      const { leftMesh, rightMesh } = buildMeshes(this.pivot);

      this.leftMesh = leftMesh;
      this.rightMesh = rightMesh;
    }

    if (this.leftSound && this.rightSound) {
      this.leftSound.play();
      this.rightSound.play();
    } else {
      const { leftSound, rightSound } = buildSounds(
        listener,
        this.video,
        this.leftMesh,
        this.rightMesh,
        this.config
      );

      leftSound.play();
      rightSound.play();

      this.leftSound = leftSound;
      this.rightSound = rightSound;
    }
  }

  remove() {
    if (this.leftMesh) {
      this.leftMesh.remove();
      this.rightMesh.remove();
    }

    if (this.leftSound) {
      this.leftSound.disconnect();
      this.rightSound.disconnect();
    }
  }

  update({
    droneModulation,
    reverbVsFastPulse,
    pulseLevel,
    lpgTrigger1,
    lpgTrigger2,
    modulationAmount,
    volume = 1,
    x0,
    y0,
    z0 = 0,
    x1,
    y1,
    z1 = 0,
    aux1,
    aux2,
    aux3,
    aux4,
  }) {
    const [scaleX, scaleY, scaleZ] = this.playArea;
    const [centerX, centerY, centerZ] = this.playAreaCenter; //.map(x => - .5));

    this.leftTarget.set(
      centerX + (x0 - 0.5) * scaleX,
      centerY + (y0 - 0.5) * scaleY,
      centerZ + (y0 - 0.5) * scaleZ
    );
    this.rightTarget.set(
      centerX + (x1 - 0.5) * scaleX,
      centerY + (y1 - 0.5) * scaleY,
      centerZ + (y1 - 0.5) * scaleZ
    );

    this.leftSize = Math.max(reverbVsFastPulse * 7, 1.2);
    this.rightSize = Math.max(reverbVsFastPulse * 7, 1.2);

    // console.log('envelope', pegEnvelope1);

    this.leftMesh.setScale(this.leftSize);
    this.rightMesh.setScale(this.leftSize);
    // this.rightMesh.setScale(this.rightSize);

    const color = nonModulatedColor
      .clone()
      .lerpHSL(modulatedColor, droneModulation);

    this.leftMesh.setColor(color);
    this.rightMesh.setColor(color);

    this.leftMesh.setIntensity(
      getTargetIntensity(volume, pulseLevel, lpgTrigger1)
    );
    this.rightMesh.setIntensity(
      getTargetIntensity(volume, pulseLevel, lpgTrigger2)
    );

    this.leftMesh.setOpacity(volume);
    this.rightMesh.setOpacity(volume);

    this.leftMesh.moveTo(this.leftTarget, MOTION_LERP);
    this.rightMesh.moveTo(this.rightTarget, MOTION_LERP);
  }
}
