import { loadModel } from "modules/environment";
import { ensureFirebaseAuth, db, getDownloadUrl } from "../../db";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

import * as THREE from "three";

const loadFbxPromise = (path, loader) => {
  return new Promise((resolve) => {
    loader.load(path, resolve);
  });
};

function toDistanceToSquared(distance) {
  return new THREE.Vector3(0, 0, 0).distanceToSquared(
    new THREE.Vector3(distance, 0, 0)
  );
}

export class Model {
  constructor(
    scene,
    {
      scale,
      color,
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      castShadow = false,
      receiveShadow = false,
      modelPath,
      modelType,
    }
  ) {
    this.scene = scene;
    this.scale = scale;
    this.color = color;
    this.castShadow = castShadow;
    this.receiveShadow = receiveShadow;
    this.position = position;
    this.rotation = rotation;
    this.modelPath = modelPath;
    this.modelType = modelType;

    console.log("animated?", this.animated, modelPath);

    this.loaded = false;
    this.loading = false;

    this.mesh = null;
  }

  ensureHidden() {
    if (this.mesh && this.mesh.visible) {
      this.mesh.visible = false;
    }
  }

  async loadModel() {
    if (this.loading) return;

    this.loading = true;

    const downloadPath = getDownloadUrl(this.modelPath);

    const receiveShadow = true;

    if (this.modelType === "glb") {
      const gLTFLoader = new GLTFLoader();

      const { scene, children, animations } = await loadModel(
        gLTFLoader,
        downloadPath,
        null,
        this.scale,
        this.castShadow,
        this.receiveShadow,
        this.position
      );
      // scene.position.set();
      // console.log(this.position, children);
      // scene.rotation.set(...this.rotation);
      this.mesh = scene;

      this.mesh.rotation.set(...this.rotation);
      this.animations = animations;
    } else if (this.modelType === "fbx") {
      const loader = new FBXLoader();

      const fbxModel = await loadFbxPromise(downloadPath, loader);
      this.animations = fbxModel.animations;
      fbxModel.position.set(...this.position);
      fbxModel.rotation.set(...this.rotation);
      fbxModel.scale.set(this.scale, this.scale, this.scale);
      if (this.castShadow)
        fbxModel.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true;
          }
        });

      fbxModel.receiveShadow = receiveShadow;
      // console.log(fbxModel);
      this.mesh = fbxModel;
    }

    if (this.animations) {
      this.animationMixer = new THREE.AnimationMixer(this.mesh);
      this.animations.forEach((clip) => {
        this.animationMixer.clipAction(clip).play();
      });
    }

    this.scene.add(this.mesh);

    // children.forEach(child => child.position.set(0,0,0));

    // console.log('loaded', children);

    this.loaded = true;
    this.loading = false;
  }

  setMeshProperties(position, rotation, scale) {
    if (this.mesh) {
      this.mesh.position.set(...position);
      this.mesh.rotation.set(...rotation);
      this.mesh.scale.set(scale, scale, scale);
    }
    this.position = position;
    this.rotation = rotation;
    this.scale = scale;
  }

  ensureLoadedAndRendered() {
    if (this.loading) return;

    if (!this.loaded) {
      this.loadModel();
      return;
    }

    if (!this.mesh.visible) {
      this.mesh.visible = true;
    }
  }

  animate(deltaSeconds) {
    if (this.animationMixer && this.mesh.visible) {
      this.animationMixer.update(deltaSeconds);
    }
  }
}

export class MultiQualityModel {
  constructor(
    scene,
    {
      active,
      modelsActive,
      position,
      rotation,
      animated = false,
      castShadow = false,
      receiveShadow = false,
      scale = 1,
      color,
      lowPolyPath,
      lowPolyType = "glb",
      lowPolyDistance,
      highPolyPath,
      highPolyDistance,
      highPolyType = "glb",
    }
  ) {
    this.active = active;
    this.position = position;
    this.camera = camera;
    this.lowPolyPath = lowPolyPath;
    this.positionVector = new THREE.Vector3(...this.position);
    this.modelsActive = modelsActive;
    this.animated = animated;

    if (lowPolyDistance) {
      // console.log("scale", scale);
      this.lowPolyModel = new Model(scene, {
        color,
        scale,
        position,
        rotation,
        castShadow,
        receiveShadow,
        modelPath: lowPolyPath,
        modelType: lowPolyType,
      });

      this.lowPolySqDistance = toDistanceToSquared(lowPolyDistance);
    }
    if (highPolyDistance) {
      this.highPolyModel = new Model(scene, {
        color,
        scale,
        position,
        rotation,
        castShadow,
        receiveShadow,
        modelPath: highPolyPath,
        modelType: highPolyType,
      });
      this.highPolySqDistance = toDistanceToSquared(highPolyDistance);
    }
  }

  setModelsActive(modelsActive) {
    this.modelsActive = modelsActive;
  }

  shouldRenderHighPoly(playerPosition) {
    if (!this.active || !this.modelsActive) return false;
    if (!this.highPolySqDistance) return false;

    return (
      playerPosition.distanceToSquared(new THREE.Vector3(...this.position)) <=
      this.highPolySqDistance
    );
  }

  shouldRenderLowPoly(playerPosition) {
    if (!this.active || !this.modelsActive) return false;
    if (!this.lowPolySqDistance) return false;

    return (
      playerPosition.distanceToSquared(new THREE.Vector3(...this.position)) <=
      this.lowPolySqDistance
    );
  }

  update(playerPosition) {
    if (this.shouldRenderHighPoly(playerPosition)) {
      if (this.lowPolyModel) {
        this.lowPolyModel.ensureHidden();
      }

      this.highPolyModel.ensureLoadedAndRendered();

      return;
    } else if (this.shouldRenderLowPoly(playerPosition)) {
      if (this.highPolyModel) {
        this.highPolyModel.ensureHidden();
      }

      this.lowPolyModel.ensureLoadedAndRendered();
    } else {
      if (this.highPolyModel) {
        this.highPolyModel.ensureHidden();
      }

      if (this.lowPolyModel) {
        this.lowPolyModel.ensureHidden();
      }
    }
  }

  animate(timeDelta) {
    if (!this.animated) return;

    if (this.lowPolyModel) {
      this.lowPolyModel.animate(timeDelta);
    }
    if (this.highPolyModel) {
      this.highPolyModel.animate(timeDelta);
    }
  }

  updateConfig({
    active,
    position,
    rotation,
    scale,
    lowPolyDistance,
    highPolyDistance,
    animated,
  }) {
    if (this.highPolyModel) {
      this.highPolyModel.setMeshProperties(position, rotation, scale);
    }
    if (this.lowPolyModel) {
      this.lowPolyModel.setMeshProperties(position, rotation, scale);
    }

    this.active = active;
    this.position = position;
    this.rotation = rotation;
    this.animated = animated;
    this.positionVector = new THREE.Vector3(...this.position);

    if (lowPolyDistance) {
      this.lowPolySqDistance = toDistanceToSquared(lowPolyDistance);
    }
    if (highPolyDistance) {
      this.highPolySqDistance = toDistanceToSquared(highPolyDistance);
    }
  }
}

function parseConfig(config, modelsActive) {
  return {
    ...config,
    modelsActive,
    position: config.position
      ? config.position.split(",").map((x) => +x)
      : [0, 0, 0],
    rotation: config.rotation
      ? config.rotation.split(",").map((x) => +x)
      : [0, 0, 0],
  };
}

export default class RemoteControlledMultiQualityModels {
  constructor(scene) {
    this.scene = scene;

    this.multiQualityModels = {};

    this.modelsActive = false;

    this.clock = new THREE.Clock();
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("elements")
      .onSnapshot((doc) => {
        this.modelsActive = doc.data().modelsActive;

        Object.values(this.multiQualityModels).forEach((model) =>
          model.setModelsActive(this.modelsActive)
        );
      });

    db.collection("models").onSnapshot((query) => {
      const modelConfigs = {};

      query.forEach((doc) => {
        modelConfigs[doc.id] = parseConfig(doc.data(), this.modelsActive);
      });

      this.updateModelConfigs(modelConfigs);
    });
  }

  updateModelConfigs(modelConfigs) {
    Object.entries(modelConfigs).forEach(([modelId, modelConfig]) => {
      if (this.multiQualityModels[modelId]) {
        this.multiQualityModels[modelId].updateConfig(modelConfig);
      } else {
        const newModel = new MultiQualityModel(this.scene, modelConfig);
        this.multiQualityModels[modelId] = newModel;
      }
    });
  }

  update(cameraPosition) {
    Object.values(this.multiQualityModels).forEach((model) =>
      model.update(cameraPosition)
    );
  }

  animate() {
    const delta = this.clock.getDelta();
    Object.values(this.multiQualityModels).forEach((model) =>
      model.animate(delta)
    );
  }
}
