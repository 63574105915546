let protooPort = 443;

if (window.location.hostname === "test.mediasoup.org") protooPort = 4444;

const SERVER_HOST = process.env.SERVER_HOST;

const SERVER_PORT = process.env.SERVER_PORT;

export function getProtooUrl({ roomId, peerId, forceH264, forceVP9 }) {
  let url = `wss://${SERVER_HOST}:${SERVER_PORT}/?roomId=${roomId}&peerId=${peerId}`;

  if (forceH264) url = `${url}&forceH264=true`;
  else if (forceVP9) url = `${url}&forceVP9=true`;

  return url;
}
