import "firebase/auth";
import * as THREE from "three";

import SwitchingStreamScreen from "./switchingStreamScreen";
import MultiStreamScreen from "./multiStreamScreen";

class RemoteControlledStreams {
  constructor(scene, camera, streamConfigs) {
    this.scene = scene;
    this.camera = camera;

    this.raycaster = new THREE.Raycaster();

    this.streams = Object.values(streamConfigs).map(
      ({ id, builder, config, type }) => {
        if (type === "switching")
          return new SwitchingStreamScreen(scene, camera, id, builder);
        else return new MultiStreamScreen(scene, camera, id, builder);
      }
    );
  }

  async load() {
    await this.streams.forEach(async (stream) => await stream.load());
  }

  play(listener) {
    this.streams.forEach((stream) => stream.play(listener));
  }

  update(raycaster) {
    // this.raycaster.setFromCamera(this.camera);
    this.streams.forEach((stream) => stream.update(raycaster));
  }
}

export default RemoteControlledStreams;
