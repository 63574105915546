import * as THREE from "three";
const { ensureFirebaseAuth, db } = require("../db");

function createSpotLight(
  scene,
  {
    position,
    target,
    castShadow = true,
    color = 0xffffff,
    intensity = 0.8,
    distance = 10,
    angle = Math.PI / 2,
    penumbra = 0,
    decay = 2,
  }
) {
  const light = new THREE.SpotLight(
    color,
    intensity,
    distance,
    angle,
    penumbra,
    decay
  );

  light.castShadow = castShadow;

  // console.log("position and target", position, target);

  light.position.set(...position);

  const targetObject = new THREE.Object3D();
  targetObject.position.set(...target);

  light.target = targetObject;
  light.visible = false;

  scene.add(light);
  scene.add(light.target);

  return { light, targetObject };
}

function parseSpotlightConfig(config, defaultConfig) {
  return {
    ...defaultConfig,
    ...config,
    position: config.position
      ? config.position.split(",").map((x) => +x)
      : [0, 0, 0],
    target: config.target ? config.target.split(",").map((x) => +x) : [0, 0, 0],
  };
}

export default class RemoteControlledLights {
  constructor(scene) {
    this.scene = scene;
    this.spotlightConfigs = [];
    this.spotLightsAndTargets = [];
    this.spotLightsActive = false;

    this.defaultConfig = {};
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("elements")
      .onSnapshot((doc) => {
        const { spotLightsActive } = doc.data();

        this.spotLightsActive = spotLightsActive;
      });

    db.collection("lights")
      .doc("fixtures")
      .onSnapshot((doc) => {
        this.defaultConfig = doc.data().defaults;

        this.updateSpotlights();
      });

    db.collection("lights")
      .doc("fixtures")
      .collection("spotLights")
      .onSnapshot((querySnapshot) => {
        const spotlightConfigs = [];

        querySnapshot.forEach((doc) => {
          spotlightConfigs.push(
            parseSpotlightConfig(doc.data(), this.defaultConfig)
          );
        });

        this.spotlightConfigs = spotlightConfigs;

        this.updateSpotlights();
      });
  }

  updateSpotlights() {
    this.spotLightsAndTargets.forEach(({ light, targetObject }) => {
      this.scene.remove(light);
      this.scene.remove(targetObject);
    });

    this.spotLightsAndTargets = this.spotlightConfigs.map((config) => {
      return createSpotLight(this.scene, config);
    });
  }

  update(playerPosition) {
    this.spotLightsAndTargets.forEach(({ light }, i) => {
      if (!this.spotLightsActive) {
        light.visible = false;
        return;
      }

      const config = this.spotlightConfigs[i];
      if (!config) return;
      light.visible = config.active;

      if (!light.visible) {
        const distanceTo = playerPosition.distanceTo(light.position);

        if (distanceTo <= config.maxDistance) {
          // turn light on - once its on dont remove it
          light.visible = true;
        }
      }
    });
  }
}
