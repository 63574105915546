const { ensureFirebaseAuth, db, getDownloadUrl } = require("../db");
import * as THREE from "three";

const fileNames = [
  "posx.jpg",
  "negx.jpg",
  "posy.jpg",
  "negy.jpg",
  "posz.jpg",
  "negz.jpg",
];

function getCubeMapDownloadPaths(bucketPath) {
  return fileNames.map((fileName) =>
    getDownloadUrl(`${bucketPath}/${fileName}`)
  );
}

export default class RemoteControlledBackground {
  constructor(scene) {
    this.scene = scene;

    this.backgrounds = {};

    this.currentBackground = null;

    // Helpers
    this.helperGrid = new THREE.GridHelper(500, 500);
    this.helperGrid.position.y = -0.1; // offset the grid down to avoid z fighting with floor

    this.showingGrid = false;
  }

  get envMap() {
    return this.currentBackground;
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("background")
      .onSnapshot((doc) => {
        const { cube_map, cube_maps, show_grid } = doc.data();
        const cubeMapPath = cube_maps[cube_map];

        if (!cubeMapPath) {
          console.error(`no cube map path for ${cubeMapPath}`);
        }

        this.setCubeMap(cube_map, cubeMapPath);

        this.toggleShowGrid(show_grid);
      });
  }

  setCubeMap(cubeMapId, cubeMapBucketFolder) {
    if (!this.backgrounds[cubeMapId]) {
      let cubeMapPaths;
      try {
        cubeMapPaths = getCubeMapDownloadPaths(cubeMapBucketFolder);
      } catch (e) {
        console.error(e);
        return;
      }

      const cubeMap = new THREE.CubeTextureLoader().load(cubeMapPaths, () => {
        this.scene.background = cubeMap;
      });

      this.backgrounds[cubeMapId] = cubeMap;
    } else {
      this.scene.background = this.backgrounds[cubeMapId];
    }
  }

  toggleShowGrid(showGrid) {
    if (showGrid !== this.showingGrid) {
      if (showGrid) {
        this.scene.add(this.helperGrid);
      } else {
        this.scene.remove(this.helperGrid);
      }

      this.showingGrid = showGrid;
    }
  }
}
