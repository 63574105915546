// import { muxUrl } from "../streaming/streamScreen";

// const modularSoundsId = "6zpDDbTvzyaireU49k01W6XXhMANIr01k2jxME01ERidN8";

// const carlCoxAwakningsId = "vHoWzclZP8201Q02UI007nBnXAbThdZbMfit11JUuwX3lQ";

import { buildStreamScreen } from "modules/streaming/streamScreen";
import { buildDansPerformance } from "./projects/dan";

const shapeAndCrop = (
  width,
  height,
  [cropLeft, cropBottom, cropRight, cropTop]
) => {
  const [cropWidth, cropHeight] = [cropRight - cropLeft, cropTop - cropBottom];

  // console.log("!!!crop size", [width, height], [cropWidth, cropHeight]);

  return {
    shape: [0.01, height * cropHeight, width * cropWidth],
    crop: [cropLeft, cropBottom, cropRight, cropTop],
  };
};

const dansPerformanceConfig = () => {
  const height = 4;
  const ratio = 16.0 / 9;
  const width = height * ratio;

  const rotationStart = Math.PI / 2;
  const rotationStep = Math.PI / 5;

  return {
    id: "dansPerformance",
    type: "switching",
    builder: buildDansPerformance,
    config: {
      centerRoomPosition: [-22, 1, -10],
      projectionSurfaces: {
        bitwig: {
          position: [0, 0.5, -8],
          rotation: [0, rotationStart + rotationStep, 0],
          ...shapeAndCrop(width, height, [0.1, 0.701, 0.4, 1]),
        },
        elgato: {
          position: [0, 0.5, 0],
          rotation: [0, 0, 0],
          ...shapeAndCrop(width, height, [0, 0.0, 0.702, 0.701]),
        },
        webcam: {
          position: [1, 0.5, 4],
          rotation: [0, rotationStart + rotationStep * 3, 0],
          ...shapeAndCrop(width, height, [0.701, 0.0, 1, 0.401]),
        },
        softube: {
          position: [2, 0.5, 6],
          rotation: [0, rotationStart + rotationStep * 4, 0],
          ...shapeAndCrop(width, height, [0.701, 0.54, 1, 1.0]),
        },
      },
      pixelDisplay: {
        outputPixelSize: 0.1,
        position: [0, 0, 0],
      },
      soundObjects: {
        playArea: [10, 2, 5],
      },
    },
  };
};

export function getStreamConfigs() {
  const redSquare = {
    id: "redSquare",
    builder: buildStreamScreen,
    type: "switching",
  };

  const videos = {
    id: "videos",
    builder: buildStreamScreen,
    type: "multi",
  };

  const ellensVideos = {
    id: "ellensVideos",
    builder: buildStreamScreen,
    type: "multi",
  };

  const djRoom = {
    id: "djRoom",
    builder: buildStreamScreen,
    type: "switching",
  };

  const dansPerformance = dansPerformanceConfig();

  return {
    djRoom,
    redSquare,
    videos,
    ellensVideos,
    dansPerformance,
  };
}
