import p5 from "p5";
import { webcamState } from "../webcamState";
import { gameState } from "../gameState";
const { getDownloadUrl } = require("../db");

// adapted (with ❤️) from Dan Shiffman: https://www.youtube.com/watch?v=rNqaw8LT2ZU
export async function createSelfView() {
  const s = (sketch) => {
    let video;
    const vScale = 5;
    let ballX = 100;
    let ballY = 100;
    let velocityX = sketch.random(-5, 5);
    let velocityY = sketch.random(-5, 5);
    const buffer = 10;

    // eslint-disable-next-line no-param-reassign
    sketch.setup = () => {
      sketch.createCanvas(260, 200);
      ballX = sketch.width / 2;
      ballY = sketch.height / 2;
      sketch.pixelDensity(1);
      video = sketch.createCapture(sketch.VIDEO);
      video.size(sketch.width / vScale, sketch.height / vScale);
      video.hide();
      sketch.frameRate(5);
      sketch.rectMode(sketch.CENTER);
      sketch.ellipseMode(sketch.CENTER);
    };

    // eslint-disable-next-line no-param-reassign
    sketch.draw = () => {
      if (webcamState.videoPaused) {
        // bouncing ball easter egg sketch:
        sketch.background(10, 10, 200);
        ballX += velocityX;
        ballY += velocityY;
        if (ballX >= sketch.width - buffer || ballX <= buffer) {
          velocityX = -velocityX;
        }
        if (ballY >= sketch.height - buffer || ballY <= buffer) {
          velocityY = -velocityY;
        }
        sketch.fill(240, 120, 0);
        sketch.ellipse(ballX, ballY, 10, 10);
      } else {
        sketch.background(0);
        video.loadPixels();
        for (let y = 0; y < video.height; y++) {
          for (let x = 0; x < video.width; x++) {
            const index = (video.width - x + 1 + y * video.width) * 4;
            const r = video.pixels[index + 0];
            const g = video.pixels[index + 1];
            const b = video.pixels[index + 2];
            const bright = (r + g + b) / 3;
            const w = sketch.map(bright, 0, 255, 0, vScale);
            sketch.noStroke();
            sketch.fill(255);
            sketch.rectMode(sketch.CENTER);
            sketch.rect(x * vScale, y * vScale, w, w);
          }
        }
      }
    };
  };
  const selfViewSketch = new p5(
    s,
    document.getElementById("self-view-canvas-container")
  );
  selfViewSketch.canvas.style = "display: block; margin: 0 auto;";

  return selfViewSketch;
}

// creates minimap p5 sketch
export async function createMiniMap() {
  const s = (sketch) => {
    let mapImg = false;
    let mapUrl = getDownloadUrl(`/maps/map-with-subway.png`);
    console.log(mapUrl);

    // eslint-disable-next-line no-param-reassign
    sketch.setup = () => {
      // eslint-disable-next-line global-require
      // mapImg = sketch.loadImage(require("./assets/images/map.png"));
      mapImg = sketch.loadImage(mapUrl);
      sketch.createCanvas(300, 300);
      sketch.pixelDensity(1);
      sketch.frameRate(5);
      sketch.ellipseMode(sketch.CENTER);
      sketch.imageMode(sketch.CENTER);
      sketch.angleMode(sketch.RADIANS);
    };

    // eslint-disable-next-line no-param-reassign
    sketch.draw = () => {
      sketch.background(0);
      sketch.push();

      // translate to center of sketch
      sketch.translate(sketch.width / 2, sketch.height / 2);
      // translate to 0,0 position of map and make all translations from there
      const playerPosition = gameState.getPlayerState();
      const posX = playerPosition.position[0];
      const posZ = playerPosition.position[2];

      // TODO add in direction...
      // let myDir = playerPosition[1][1]; // camera rotation about Y in Euler Radians

      // always draw player at center:
      sketch.push();
      sketch.fill(255, 255, 0);
      sketch.ellipse(0, 0, 7, 7);
      // TODO add in direction...
      // sketch.fill(0, 0, 255,150);
      // sketch.rotate(myDir);
      // sketch.triangle(0, 0, -10, -30, 10, -30);
      sketch.pop();

      const mappedX = sketch.map(posZ, 0, 32, 0, -225, false);
      const mappedY = sketch.map(posX, 0, 32, 0, 225, false);
      // allow for map load time without using preload, which seems to mess with things in p5 instance mode...
      sketch.push();
      sketch.rotate(Math.PI);
      sketch.translate(mappedX, mappedY);
      if (mapImg) {
        sketch.image(mapImg, 0, 0, mapImg.width, mapImg.height);
      }
      // const clients = getClients();
      const clientPositions = gameState.getClientPositions();
      for (let i = 0; i < clientPositions.length; i++) {
        const pos = clientPositions[i]; // [x,y,z] array of position
        const yPos = sketch.map(pos.x, 0, 32, 0, -225, false);
        const xPos = sketch.map(pos.z, 0, 32, 0, 225, false);
        sketch.push();
        sketch.fill(100, 100, 255);
        sketch.translate(xPos, yPos);
        sketch.ellipse(0, 0, 5, 5);
        sketch.pop();
      }
      sketch.pop();
      sketch.pop();
    };
  };
  const miniMapSketch = new p5(
    s,
    document.getElementById("mini-map-canvas-container")
  );
  miniMapSketch.canvas.style = "display: block; margin: 0 auto;";

  return miniMapSketch;
}
