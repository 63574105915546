import * as THREE from "three";
import {
  addVideoElement,
  loadMuxStream,
  unloadStream,
  buildPlaySurfaces,
  loadVideoPaths,
  loadStoredVideo,
  muxUrl,
  seekVideoToSyncedPosition,
} from "./utils";
import { getDownloadUrl } from "../../db";

const PLAY_SPHERE_VISIBLE = false;

class StreamPositionalSound {
  constructor(mesh, videoElement, { refDistance, rollOffFactor }) {
    this.mesh = mesh;
    this.videoElement = videoElement;
    this.audioSource = null;
    this.refDistance = refDistance;
    this.rollOffFactor = rollOffFactor;
    this.playing = false;
  }

  play(listener) {
    if (this.playing) return;

    this.audioSource = listener.context.createMediaElementSource(
      this.videoElement
    );

    // create the PositionalAudio object (passing in the listener)
    const sound = new THREE.PositionalAudio(listener);
    sound.setNodeSource(this.audioSource);

    this.mesh.add(sound);

    sound.setRefDistance(this.refDistance);
    sound.setRolloffFactor(this.rollOffFactor);
    sound.setDistanceModel("exponential");

    sound.play();

    this.sound = sound;

    this.playing = true;
  }

  remove() {
    if (this.sound) {
      // console.log("disconnecting sound");
      this.sound.disconnect();
      this.sound = null;
    }
    if (this.audioSource) {
      // console.log("disconnecting source");
      this.audioSource.disconnect();
      this.audioSource = null;
    }
  }
}

async function loadStream(videoElement, videoType, muxId, videoFolder) {
  if (videoType === "muxStream") {
    if (!muxId) {
      throw new Error("video type is muxStream but muxId is missing");
    }

    const url = muxUrl(muxId);

    loadMuxStream(videoElement, url);
  } else if (videoType === "storedVideo") {
    if (!videoFolder) {
      throw new Error("video type is storedVideo but videoFolder is missing");
    }

    const videoPaths = await loadVideoPaths(videoFolder);

    loadStoredVideo(videoElement, videoPaths);
  }
}

const inwardMaterial = new THREE.MeshBasicMaterial({
  side: THREE.BackSide,
});

const outwardMaterial = new THREE.MeshBasicMaterial();

const loadingTexture = new THREE.TextureLoader().load(
  require("modules/assets/images/loading.gif")
);

export default class StreamScreen {
  constructor(scene, camera) {
    this.scene = scene;
    this.camera = camera;
    this.playSettings = null;
    this.refDistance = null;
    this.rollOffFactor = null;

    this.playSurfaces = null;
    this.playing = false;
    this.pivot = new THREE.Object3D();
    scene.add(this.pivot);

    this.paused = true;

    this.videoSizeSet = false;
  }

  getOrAddVideoElement() {
    if (this.videoElement) return this.videoElement;
    this.videoSizeSet = false;

    this.videoElement = addVideoElement();

    this.videoElement.addEventListener("loadedmetadata", (e) => {
      const { videoWidth, videoHeight } = e.target;

      this.videoElement.width = videoWidth;
      this.videoElement.height = videoHeight;

      this.seekVideoToSyncedPositionIfPossible();

      this.videoSizeSet = true;
    });

    return this.videoElement;
  }

  remove() {
    this.removeSound();

    this.removePlaySurfaces();

    this.removePlaySphere();

    if (this.videoElement) {
      unloadStream(this.videoElement);
      this.videoElement.parentNode.removeChild(this.videoElement);
      this.videoElement = null;
    }

    this.videoSizeSet = false;
    this.playing = false;
  }

  buildPlaySurfaces() {
    this.removePlaySurfaces();

    const { playSurfaces, videoTextures } = buildPlaySurfaces(
      this.getOrAddVideoElement(),
      this.pivot,
      this.surfacesConfig
    );

    this.playSurfaces = playSurfaces;
    this.videoTextures = videoTextures;

    if (this.thumbnailTexture) {
      playSurfaces.forEach((surface) => {
        surface.material.map = this.thumbnailTexture;
      });
    }
  }

  buildPlaySphere() {
    this.removePlaySphere();

    if (!this.playSettings) return;

    const {
      auto,
      playSphereRadius,
      playSphereVisible = false,
    } = this.playSettings;
    if (!auto && playSphereRadius) {
      const playSphereGeometry = new THREE.SphereGeometry(playSphereRadius);

      this.playSpheres = [
        new THREE.Mesh(playSphereGeometry, inwardMaterial),
        new THREE.Mesh(playSphereGeometry, outwardMaterial),
      ];

      this.playSpheres.forEach((playSphere) => {
        playSphere.visible = playSphereVisible;
        this.pivot.add(playSphere);
      });
    }
  }

  removeSound() {
    if (this.sound) {
      this.sound.remove();

      this.sound = null;
    }
  }

  removePlaySurfaces() {
    if (this.playSurfaces) {
      for (const playSurface of this.playSurfaces) {
        playSurface.material.dispose();
        playSurface.geometry.dispose();
        this.pivot.remove(playSurface);
      }

      this.playSurfaces = null;
    }
  }

  removePlaySphere() {
    if (this.playSpheres && this.pivot) {
      this.playSpheres.forEach((sphere) => this.pivot.remove(sphere));
    }
  }

  set position([x, y, z]) {
    this.pivot.position.set(x, y, z);
  }

  async updateControls({
    playSettings,
    videoType,
    muxId,
    videoFolder,
    position,
    sound: { refDistance, rollOffFactor },
    surfaces,
  }) {
    this.remove();

    this.playSettings = playSettings;
    this.surfacesConfig = surfaces;
    this.videoType = videoType;
    this.position = position;

    await loadStream(
      this.getOrAddVideoElement(),
      videoType,
      muxId,
      videoFolder
    );

    if (videoFolder) {
      if (this.thumbnailTexture) {
        this.thumbnailTexture.dispose();
      }

      const thumbnailPath = `${videoFolder}/thumbnail.jpg`;

      this.thumbnailTexture = new THREE.TextureLoader().load(
        getDownloadUrl(thumbnailPath)
      );
    }

    this.buildPlaySurfaces();

    this.buildPlaySphere();

    this.refDistance = refDistance;
    this.rollOffFactor = rollOffFactor;
  }

  shouldPlay(raycaster) {
    // return true;
    if (!this.playSettings) return false;
    if (this.playSettings.auto) {
      return true;
    }

    const distanceTo = this.camera.position.distanceTo(this.pivot.position);

    if (distanceTo > this.playSettings.maxDistance) {
      return false;
    }

    if (this.playSpheres) {
      return raycaster.intersectObjects(this.playSpheres).length > 0;
    } else return raycaster.intersectObjects(this.playSurfaces).length > 0;
  }

  updatePlayStatus(raycaster) {
    const shouldPlay = this.shouldPlay(raycaster);
    if (shouldPlay) {
      this.ensurePlaying();
    } else {
      this.ensurePaused();
    }
  }

  setListener(listener) {
    this.listener = listener;
  }

  async ensurePlaying() {
    if (!this.listener) return;

    if (this.playing || this.startingPlaying) return;

    this.playSurfaces.forEach(
      (surface) => (surface.material.map = loadingTexture)
    );

    if (!this.sound) {
      this.sound = this.createSound();
      this.sound.play(this.listener);
    }

    this.seekVideoToSyncedPositionIfPossible();

    this.startingPlaying = true;

    try {
      await this.videoElement.play();
      this.playSurfaces.forEach((surface, i) => {
        surface.material.map = this.videoTextures[i];
      });
      if (this.animate) this.animate();
      this.startingPlaying = false;
      this.playing = true;
    } catch (e) {
      this.startingPlaying = false;
    }
  }

  seekVideoToSyncedPositionIfPossible() {
    if (this.videoType !== "storedVideo") return;

    if (this.videoElement && this.videoElement.duration) {
      const currentTimeMs = new Date().getTime();
      seekVideoToSyncedPosition(this.videoElement, currentTimeMs);
    }
  }

  createSound() {
    return new StreamPositionalSound(this.pivot, this.videoElement, {
      refDistance: this.refDistance,
      rollOffFactor: this.rollOffFactor,
    });
  }

  ensurePaused() {
    if (!this.playing) return;

    if (this.videoElement) {
      this.videoElement.pause();
    }

    this.playing = false;
  }
}

export function buildStreamScreen(scene, camera) {
  const streamScreen = new StreamScreen(scene, camera);

  return streamScreen;
}
