import * as THREE from "three";
import { range } from "libs/utils";

const ORIGINAL_WIDTH = 1920;
const ORIGINAL_HEIGHT = 1080;

function drawCroppedVideoOntoCanvas(
  canvas,
  videoElement,
  outputWidth,
  outputHeight,
  scale
) {
  const ctx = canvas.getContext("2d");

  ctx.save();

  ctx.scale(
    ORIGINAL_WIDTH / videoElement.videoWidth,
    ORIGINAL_HEIGHT / videoElement.videoHeight
  );

  ctx.drawImage(
    videoElement,
    0,
    0,
    outputWidth,
    outputHeight,
    0,
    0,
    outputWidth,
    outputHeight
  );

  ctx.restore();
}

const HIDDEN = true;

function createCanvasAndTexture(outputWidth, outputHeight) {
  const outputCanvas = document.createElement("canvas");
  const display = HIDDEN ? "display:none" : "";
  outputCanvas.style = `position:absolute; z-index: 1000; ${display}`;
  document.body.appendChild(outputCanvas);
  outputCanvas.width = outputWidth;
  outputCanvas.height = outputHeight;

  const canvasTexture = new THREE.CanvasTexture(outputCanvas);
  canvasTexture.magFilter = THREE.NearestFilter;

  return { outputCanvas, canvasTexture };
}

const MAX_VALUE = 255.0;

export default class MidiExtractor {
  constructor(videoElement, pixelSize, pixelFields) {
    this.videoElement = videoElement;
    this.pixelSize = pixelSize;
    this.pixelFields = pixelFields;

    this.outputCanvas = null;

    this.setup();
  }

  setup() {
    const { outputCanvas, canvasTexture } = createCanvasAndTexture(
      this.outputWidth,
      this.outputHeight
    );

    this.outputCanvas = outputCanvas;
    this.canvasTexture = canvasTexture;

    this.animate();
  }

  get outputWidth() {
    return this.pixelSize;
  }

  get outputHeight() {
    return this.pixelSize * this.pixelFields.length;
  }

  animate() {
    drawCroppedVideoOntoCanvas(
      this.outputCanvas,
      this.videoElement,
      this.outputWidth,
      this.outputHeight
    );

    this.canvasTexture.needsUpdate = true;

    this.animationFrame = window.requestAnimationFrame(() => this.animate());
  }

  remove() {
    if (this.animationFrame) window.cancelAnimationFrame(this.animationFrame);

    if (this.canvasTexture) this.canvasTexture.dispose();

    if (this.outputCanvas) this.outputCanvas.remove();
  }

  get texture() {
    return this.canvasTexture;
  }

  sampleField(index) {
    const topOfMidiPixel = index * this.pixelSize;
    const center = this.pixelSize / 2;

    const x = center;
    const y = topOfMidiPixel + center;

    return (
      this.outputCanvas.getContext("2d").getImageData(x, y, 1, 1).data[0] /
      MAX_VALUE
    );
  }

  getValues() {
    const result = {};

    this.pixelFields.forEach((fieldName, index) => {
      result[fieldName] = this.sampleField(index);
    });

    return result;
  }
}
