/*
 * YORB 2020
 *
 * Aidan Nelson, April 2020
 *
 */

import Stats from "stats.js";
import * as THREE from "three";
import { playerControls } from "./modules/playerControls";
import Environment from "./modules/environment";
import { getStreamConfigs } from "./modules/residentsShow/streams";
import RemoteControlledStreams from "./modules/streaming/remoteControlledStreams";
import RemoteControlledMultiQualityModels from "modules/modelWithAction/multiQualityModel";
import { gameState } from "./gameState";
// import SilentZones from "modules/silentZones";
// import ShrinkZones from "./modules/shrinkZones";
import ProjectPodiums from "./modules/projectPodiums";
import RemoteControlledLights from "./modules/lights";
import RemoteControlledTextDisplays from "./modules/textDisplay";

const ELEVEATOR_STARTING_POSITIONS = {
  x: [3, 28],
  z: [-2.5, 1.5],
};

const OUTSIDE_STARTING_POSITIONS = {
  x: [81, 83],
  z: [-3, -2],
};

const RED_SQUARE_STARTING_POSITION = {
  x: [-22, -18],
  z: [-8, -12],
};

const RANDOM_STARTING_POSITION_RANGE = RED_SQUARE_STARTING_POSITION;

const BASE_CAMERA_HEIGHT = 1.75;

class Scene {
  constructor() {
    // add this to window to allow javascript console debugging
    window.scene = this;

    // this pauses or restarts rendering and updating
    const domElement = document.getElementById("scene-container");
    this.frameCount = 0;
    this.hyperlinkedObjects = []; // array to store interactable hyperlinked meshes
    this.DEBUG_MODE = false;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();

    this.mouse = {
      x: 0,
      y: 0,
    };

    // run it once to make sure we attach all pre-existing clients
    // gameState.attachClientsToScene(this.scene);
    gameState.on("add-client", () => {
      // console.log("adding client");
      gameState.attachClientsToScene(this.scene);
    });

    // STATS for debugging:
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
    this.stats.dom.style = "visibility: hidden;";

    // THREE Camera
    this.cameraHeight = BASE_CAMERA_HEIGHT;
    this.camera = new THREE.PerspectiveCamera(
      50,
      this.width / this.height,
      0.1,
      5000
    );
    this.camera.name = "mainCamera";

    // starting position
    // elevator bank range: x: 3 to 28, z: -2.5 to 1.5
    const { x, z } = RANDOM_STARTING_POSITION_RANGE;
    const randX = randomRange(x[0], x[1]);
    const randZ = randomRange(z[0], z[1]);

    if (
      localStorage.getItem("playerTS") !== null &&
      Date.now() - localStorage.getItem("playerTS") < 60000
    ) {
      // use parseFloat to deal with weird audio context error:
      const playerX = parseFloat(localStorage.getItem("playerX"));
      const playerZ = parseFloat(localStorage.getItem("playerZ"));

      const lookAtX = parseFloat(localStorage.getItem("lookAtX"));
      const lookAtZ = parseFloat(localStorage.getItem("lookAtZ"));
      this.camera.position.set(playerX, this.cameraHeight, playerZ);
      this.camera.lookAt(
        new THREE.Vector3(lookAtX, this.cameraHeight, lookAtZ)
      );
    } else {
      this.camera.position.set(randX, this.cameraHeight, randZ);
      this.camera.lookAt(new THREE.Vector3(0, this.cameraHeight, 0));
    }

    this.scene.add(this.camera);
    window.camera = this.camera;

    // THREE WebGL renderer
    this.renderer = new THREE.WebGLRenderer({
      antialiasing: true,
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setClearColor(new THREE.Color("lightblue"));
    this.renderer.setSize(this.width, this.height);

    this.addElements();

    this.controls = playerControls;
    this.controls.setup(this.camera, this.renderer.domElement, this.raycaster);

    this.setupCollisionDetection();

    // this.silentZones = new SilentZones();
    // this.silentZones.load();

    // this.shrinkZones = new ShrinkZones();
    // this.shrinkZones.load();

    // Push the canvas to the DOM
    domElement.append(this.renderer.domElement);

    // Setup event listeners for events and handle the states
    window.addEventListener("resize", (e) => this.onWindowResize(e), false);
    document
      .getElementById("main")
      .addEventListener("click", (e) => this.onMouseClick(e), false);

    this.update();
    this.render();
  }

  addElements() {
    this.environment = new Environment(this.scene);
    this.environment.setup();

    // this.springShow = new SpringShow(this.scene, socket);
    // this.springShow.setup();

    const streamConfigs = getStreamConfigs();

    this.streams = new RemoteControlledStreams(
      this.scene,
      this.camera,
      streamConfigs
    );
    this.streams.load();

    this.projectPodiums = new ProjectPodiums(this.scene);
    this.projectPodiums.load();

    this.projectModels = new RemoteControlledMultiQualityModels(this.scene);
    this.projectModels.load();

    this.lights = new RemoteControlledLights(this.scene);
    this.lights.load();

    this.textDisplays = new RemoteControlledTextDisplays(this.scene);
    this.textDisplays.load();
  }

  swapMaterials() {
    this.environment.swapMaterials();
  }

  initializeAudio() {
    // create an AudioListener and add it to the camera
    if (!this.listener) {
      this.listener = new THREE.AudioListener();
      this.camera.add(this.listener);
    }

    return this.listener;
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Collision Detection 🤾‍♀️

  /*
   * setupCollisionDetection()
   *
   * Description:
   * This function sets up collision detection:
   * 	- creates this.collidableMeshList which will be populated by this.loadFloorModel function
   * 	- creates this.obstacles object which will be queried by player controls before performing movement
   * 	- generates arrays of collision detection points, from which we will perform raycasts in this.detectCollisions()
   *
   */
  setupCollisionDetection() {
    this.obstacles = {
      forward: false,
      backward: false,
      right: false,
      left: false,
    };

    // var numCollisionDetectionPointsPerSide = 3;
    // var numTotalCollisionDetectionPoints = numCollisionDetectionPointsPerSide * 4;

    // get the headMesh vertices
    // var headMeshVertices = this.playerGroup.children[1].geometry.vertices;

    // these are the four vertices of each side:
    // figured out which ones were which with pen and paper...
    // var forwardVertices = [headMeshVertices[1], headMeshVertices[3], headMeshVertices[4], headMeshVertices[6]];
    // var backwardVertices = [headMeshVertices[0], headMeshVertices[2], headMeshVertices[5], headMeshVertices[7]];
    // var rightVertices = [headMeshVertices[0], headMeshVertices[1], headMeshVertices[2], headMeshVertices[3]];
    // var leftVertices = [headMeshVertices[4], headMeshVertices[5], headMeshVertices[6], headMeshVertices[7]]

    // this.forwardCollisionDetectionPoints = this.getPointsBetweenPoints(headMeshVertices[6], headMeshVertices[3], numCollisionDetectionPointsPerSide);
    // this.backwardCollisionDetectionPoints = this.getPointsBetweenPoints(headMeshVertices[2], headMeshVertices[7], numCollisionDetectionPointsPerSide);
    // this.rightCollisionDetectionPoints = this.getPointsBetweenPoints(headMeshVertices[3], headMeshVertices[2], numCollisionDetectionPointsPerSide);
    // this.leftCollisionDetectionPoints = this.getPointsBetweenPoints(headMeshVertices[7], headMeshVertices[6], numCollisionDetectionPointsPerSide);

    // for use debugging collision detection
    if (this.DEBUG_MODE) {
      this.collisionDetectionDebugArrows = [];
      for (let i = 0; i < numTotalCollisionDetectionPoints; i++) {
        const arrow = new THREE.ArrowHelper(
          new THREE.Vector3(),
          new THREE.Vector3(),
          1,
          0x000000
        );
        this.collisionDetectionDebugArrows.push(arrow);
        this.scene.add(arrow);
      }
    }
  }

  /*
   * detectCollisions()
   *
   * based on method shown here:
   * https://github.com/stemkoski/stemkoski.github.com/blob/master/Three.js/Collision-Detection.html
   *
   * Description:
   * 1. Creates THREE.Vector3 objects representing the current forward, left, right, backward direction of the character.
   * 2. For each side of the cube,
   * 		- uses the collision detection points created in this.setupCollisionDetection()
   *		- sends a ray out from each point in the direction set up above
   * 		- if any one of the rays hits an object, set this.obstacles.SIDE (i.e. right or left) to true
   * 3. Give this.obstacles object to this.controls
   *
   * To Do: setup helper function to avoid repetitive code
   */
  detectCollisions() {
    // reset obstacles:
    this.obstacles = {
      forward: false,
      backward: false,
      right: false,
      left: false,
    };

    // TODO only use XZ components of forward DIR in case we are looking up or down while travelling forward
    // NOTE: THREE.PlayerControls seems to be backwards (i.e. the 'forward' controls go backwards)...
    // Weird, but this function respects those directions for the sake of not having to make conversions
    // https://github.com/mrdoob/three.js/issues/1606
    const matrix = new THREE.Matrix4();
    matrix.extractRotation(this.camera.matrix);
    const backwardDir = new THREE.Vector3(0, 0, 1).applyMatrix4(matrix);
    const forwardDir = backwardDir.clone().negate();
    const rightDir = forwardDir
      .clone()
      .cross(new THREE.Vector3(0, 1, 0))
      .normalize();
    const leftDir = rightDir.clone().negate();

    // let forwardDir = new THREE.Vector3();
    // this.controls.getDirection(forwardDir);
    // var backwardDir = forwardDir.clone().negate();
    // var rightDir = forwardDir.clone().cross(new THREE.Vector3(0, 1, 0)).normalize();
    // var leftDir = rightDir.clone().negate();

    // TODO more points around avatar so we can't be inside of walls
    const pt = this.controls.clonePosition();

    this.forwardCollisionDetectionPoints = [pt];
    this.backwardCollisionDetectionPoints = [pt];
    this.rightCollisionDetectionPoints = [pt];
    this.leftCollisionDetectionPoints = [pt];

    // check forward
    this.obstacles.forward = this.checkCollisions(
      this.forwardCollisionDetectionPoints,
      forwardDir,
      0
    );
    this.obstacles.backward = this.checkCollisions(
      this.backwardCollisionDetectionPoints,
      backwardDir,
      4
    );
    this.obstacles.left = this.checkCollisions(
      this.leftCollisionDetectionPoints,
      leftDir,
      8
    );
    this.obstacles.right = this.checkCollisions(
      this.rightCollisionDetectionPoints,
      rightDir,
      12
    );

    // this.controls.obstacles = this.obstacles;
  }

  checkCollisions(pts, dir, arrowHelperOffset) {
    // distance at which a collision will be detected and movement stopped (this should be greater than the movement speed per frame...)
    const detectCollisionDistance = 1;

    for (let i = 0; i < pts.length; i++) {
      const pt = pts[i].clone();
      // pt.applyMatrix4(this.playerGroup.matrix);
      // pt.y += 1.0; // bias upward to head area of player

      this.raycaster.set(pt, dir);
      const collided = this.environment.checkCollisions(
        this.raycaster,
        detectCollisionDistance
      );

      // arrow helpers for debugging
      if (this.DEBUG_MODE) {
        const a = this.collisionDetectionDebugArrows[i + arrowHelperOffset];
        a.setLength(detectCollisionDistance);
        a.setColor(new THREE.Color("rgb(0, 0, 255)"));
        a.position.x = pt.x;
        a.position.y = pt.y;
        a.position.z = pt.z;
        a.setDirection(dir);
      }

      if (collided) return true;
    }
    return false;
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Position Update for Socket

  getPlayerState() {
    const viewDirection = new THREE.Vector3();
    this.camera.getWorldDirection(viewDirection);

    return {
      position: [
        this.camera.position.x,
        this.camera.position.y - (this.cameraHeight - 0.5),
        this.camera.position.z,
      ],
      rotation: viewDirection.toArray(),
    };
  }

  getCameraRaycaster() {
    const worldPosition = new THREE.Vector3();
    const worldDirection = new THREE.Vector3();
    this.camera.getWorldPosition(worldPosition);
    this.camera.getWorldDirection(worldDirection);
    return new THREE.Raycaster(worldPosition, worldDirection);
  }

  checkEnvironmentCollisions = (raycaster, maxDistance) => {
    this.environment.checkCollisions(raycaster, maxDistance);
  };

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Loop ⭕️

  update() {
    this.frameCount++;

    requestAnimationFrame(() => this.update());

    if (!this.controls.paused) {
      gameState.setPlayerState(this.getPlayerState());
      this.controls.update(
        this.obstacles,
        this.cameraHeight,
        this.checkEnvironmentCollisions
      );
      if (this.frameCount % 20 === 0) {
        const cameraRaycaster = this.getCameraRaycaster();
        this.streams.update(cameraRaycaster);

        this.projectPodiums.update(cameraRaycaster);
      }

      this.detectCollisions();
    }

    // update which clients we are subscribed to every 50 frames:
    // if (this.frameCount % 50 === 0) {
    //   gameState.updateCurrentSubscriptions();
    // }

    // only update client audio every 10 frames, as this is computationally expensive
    if (this.frameCount % 20 === 0) {
      // gameState.updateClientAudio(this.silentZones);
      this.lights.update(this.camera.position);
    }

    if (this.frameCount % 10 === 0) {
      this.projectModels.update(this.camera.position);
    }

    if (this.frameCount % 5 === 0) {
      this.projectModels.animate();
    }

    // update client positions and video every grame
    // gameState.updateClients();

    // update shrink size every 50 frames
    if (this.frameCount % 50 === 0) {
      // gameState.updateClientSizes(this.shrinkZones);
    }

    this.stats.update();
    this.render();
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Rendering 🎥

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Event Handlers 🍽

  onWindowResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
  }

  onMouseClick() {
    // not used currently
    // this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    // this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
    // console.log(Click");

    this.controls.lock();
  }

  playElements(listener) {
    if (this.streams) {
      this.streams.play(listener);
    }
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Utilities:

  //= =//==//==//==//==//==//==//==// fin //==//==//==//==//==//==//==//==//==//
}

/*
 * getPointsBetweenPoints()
 *
 * Description:
 * Returns an array of numPoints THREE.Vector3 objects evenly spaced between vecA and vecB, including vecA and vecB
 *
 * based on:
 * https://stackoverflow.com/questions/21249739/how-to-calculate-the-points-between-two-given-points-and-given-distance
 *
 */
export function getPointsBetweenPoints(vecA, vecB, numPoints) {
  const points = [];
  const dirVec = vecB.clone().sub(vecA);
  for (let i = 0; i < numPoints; i++) {
    const pt = vecA
      .clone()
      .add(dirVec.clone().multiplyScalar(i / (numPoints - 1)));
    points.push(pt);
  }
  return points;
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 * https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range#1527820
 */
function randomRange(min, max) {
  return Math.random() * (max - min) + min;
}

export default Scene;
