import * as THREE from "three";
import { ensureFirebaseAuth, db } from "../db";

// this function returns 3D text object
// from https://threejs.org/examples/?q=text#webgl_geometry_text
export function create3DText(
  font,
  text,
  {
    size = 0.25,
    height = 0.1,
    curveSegments = 3,
    bevelThickness = 0.01,
    bevelSize = 0.01,
    bevelEnabled = false,
    frontColor = 0x57068c,
    sideColor = 0xffffff,
    mirror = false,
    castShadow = false,
  }
) {
  let textGeo = new THREE.TextGeometry(text, {
    font,
    size,
    height,
    curveSegments,
    bevelThickness,
    bevelSize,
    bevelEnabled,
  });

  textGeo.computeBoundingBox();
  textGeo.computeVertexNormals();

  const triangle = new THREE.Triangle();

  const materials = [
    new THREE.MeshPhongMaterial({ color: frontColor, flatShading: true }), // front
    new THREE.MeshPhongMaterial({ color: sideColor }), // side
  ];

  // "fix" side normals by removing z-component of normals for side faces
  // (this doesn't work well for beveled geometry as then we lose nice curvature around z-axis)

  if (!bevelEnabled) {
    const triangleAreaHeuristics = 0.1 * (height * size);

    for (let i = 0; i < textGeo.faces.length; i++) {
      const face = textGeo.faces[i];

      if (face.materialIndex === 1) {
        for (let j = 0; j < face.vertexNormals.length; j++) {
          face.vertexNormals[j].z = 0;
          face.vertexNormals[j].normalize();
        }

        const va = textGeo.vertices[face.a];
        const vb = textGeo.vertices[face.b];
        const vc = textGeo.vertices[face.c];

        const s = triangle.set(va, vb, vc).getArea();

        if (s > triangleAreaHeuristics) {
          for (let j = 0; j < face.vertexNormals.length; j++) {
            face.vertexNormals[j].copy(face.normal);
          }
        }
      }
    }
  }

  const centerOffset =
    -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);

  textGeo = new THREE.BufferGeometry().fromGeometry(textGeo);

  // geometry.computeBoundingBox();

  const xMid = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
  // let yMid = 0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);

  textGeo.translate(xMid, 0, 0);

  const textMesh = new THREE.Mesh(textGeo, materials);

  if (castShadow) {
    textMesh.castShadow = true;
  }
  // let hover = 5;

  // textMesh.position.x = centerOffset;
  // textMesh.position.y = hover;
  // textMesh.position.z = 0;

  // textMesh.rotation.x = 0;
  // textMesh.rotation.y = Math.PI * 2;

  if (mirror) {
    const textMesh2 = new THREE.Mesh(textGeo, materials);

    textMesh2.position.x = centerOffset;
    textMesh2.position.y = -hover;
    textMesh2.position.z = height;

    textMesh2.rotation.x = Math.PI;
    textMesh2.rotation.y = Math.PI * 2;

    return textMesh2;
  }

  return textMesh;
}

class TextDisplay {
  constructor(
    scene,
    font,
    text,
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    displayConfig = {}
  ) {
    this.scene = scene;
    this.font = font;
    this.text = text;
    this.position = position;
    this.rotation = rotation;
    this.displayConfig = displayConfig;
  }

  setup() {
    this.textMesh = create3DText(this.font, this.text, this.displayConfig);
    this.textMesh.position.set(...this.position);
    this.textMesh.rotation.set(...this.rotation);

    this.scene.add(this.textMesh);
  }

  remove() {
    if (this.textMesh) this.scene.remove(this.textMesh);
  }
}

function parseConfig(textDisplayConfig) {
  return {
    ...textDisplayConfig,
    position: textDisplayConfig.position
      ? textDisplayConfig.position.split(",").map((x) => +x)
      : [0, 0, 0],
    rotation: textDisplayConfig.rotation
      ? textDisplayConfig.rotation.split(",").map((x) => +x)
      : [0, 0, 0],
  };
}

export default class RemoteControlledTextDisplays {
  constructor(scene) {
    this.scene = scene;

    this.font = new THREE.Font(
      // eslint-disable-next-line global-require
      require("modules/assets/fonts/helvetiker_bold.typeface.json")
    );

    this.textDisplayConfigs = [];
    this.textDisplays = [];
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("textDisplays").onSnapshot((querySnapshot) => {
      const textDisplayConfigs = [];
      querySnapshot.forEach((doc) => {
        textDisplayConfigs.push(parseConfig(doc.data()));
      });

      this.updateTextDisplays(textDisplayConfigs);
    });
  }

  updateTextDisplays(textDisplayConfigs) {
    this.textDisplays.forEach((textDisplay) => textDisplay.remove());

    this.textDisplays = textDisplayConfigs
      .filter(({ active }) => active)
      .map(({ text, position, rotation, display }) => {
        const textDisplay = new TextDisplay(
          this.scene,
          this.font,
          text,
          position,
          rotation,
          display
        );
        textDisplay.setup();

        return textDisplay;
      });
  }
}
