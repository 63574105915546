import "firebase/auth";
import { db, ensureFirebaseAuth } from "../../db";

import { mergeSettingsWithDefaults } from "./switchingStreamScreen";

const stringArrayToNumbers = (stringArray) =>
  stringArray ? stringArray.split(",").map((x) => +x) : null;

export default class MultiStreamScreen {
  constructor(scene, camera, screenId, builder) {
    this.scene = scene;
    this.camera = camera;
    this.listener = null;
    this.builder = builder;
    this.screenId = screenId;

    this.streamScreens = null;
    this.audioListener = null;

    this.defaultSceneSettings = null;
    this.sceneSettingsByScreen = null;
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("screens")
      .doc(this.screenId)
      .onSnapshot((doc) => {
        const { active, scene, defaults } = doc.data();

        this.defaultSceneSettings = defaults;

        this.setActive(active);

        db.collection("screens")
          .doc(this.screenId)
          .collection("scenes")
          .onSnapshot((querySnapshot) => {
            const sceneSettingsByScreen = [];

            querySnapshot.forEach((doc) => {
              sceneSettingsByScreen.push(doc.data());
            });

            this.sceneSettingsByScreen = sceneSettingsByScreen;
            this.updateScreens();
          });
      });
  }

  setActive(active) {
    if (active && !this.active) {
      this.activate();
    }

    if (!active && this.activate) {
      this.deactivate();
    }

    this.active = active;
  }

  play(audioListener) {
    this.audioListener = audioListener;
    if (this.streamScreens) {
      this.streamScreens.forEach((streamScreen) =>
        streamScreen.setListener(this.audioListener)
      );
    }
  }

  deactivate() {
    if (this.streamScreens) {
      this.streamScreens.forEach((streamScreen) => streamScreen.remove());

      this.streamScreens = null;
    }
  }

  activate() {
    if (!this.streamScreens) {
      if (this.sceneSettingsByScreen)
        this.streamScreens = this.sceneSettingsByScreen.map((sceneSettings) =>
          this.builder(this.scene, this.camera)
        );
    }

    this.setSceneControls();

    if (this.streamScreens && this.audioListener) {
      this.streamScreens.forEach((streamScreen) =>
        streamScreen.setListener(this.audioListener)
      );
    }
  }

  setSceneControls() {
    if (this.sceneSettingsByScreen && this.streamScreens) {
      this.sceneSettingsByScreen.forEach((sceneSettings, i) => {
        const streamScreen = this.streamScreens[i];
        if (streamScreen) {
          const withDefaults = mergeSettingsWithDefaults(
            this.defaultSceneSettings,
            sceneSettings
          );

          streamScreen.updateControls(withDefaults);
        }
      });
    }
  }

  updateScreens() {
    this.deactivate();
    if (this.active) {
      this.activate();
    }
    // if (this.streamScreens) {
    //   this.setSceneControls();
    // } else {
    //   if (this.active) {
    //   }
    // }
  }

  update(raycaster) {
    if (this.streamScreens) {
      this.streamScreens.forEach((streamScreen) =>
        streamScreen.updatePlayStatus(raycaster)
      );
    }
  }
}
