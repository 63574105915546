import "firebase/auth";
import { db, ensureFirebaseAuth } from "../../db";

import { shapeFromCrop } from "./utils";

const stringArrayToNumbers = (stringArray) =>
  stringArray ? stringArray.split(",").map((x) => +x) : null;

const DEFAULT_CROP = "0,0,1,1";
const DEFAULT_POSITION = "0,0,0";
const DEFAULT_SCALE = 1;

export function mergeSettingsWithDefaults(defaultSceneSettings, sceneSettings) {
  if (!sceneSettings) return defaultSceneSettings;

  const mergedSettings = {
    ...defaultSceneSettings,
    ...sceneSettings,
  };

  const { width, height } = mergedSettings;

  const convertedSurfaces = Object.entries(mergedSettings.surfaces).reduce(
    (
      acc,
      [
        key,
        {
          crop = DEFAULT_CROP,
          position = DEFAULT_POSITION,
          scale = DEFAULT_SCALE,
          rotation,
        },
      ]
    ) => {
      acc[key] = {
        crop: stringArrayToNumbers(crop),
        position: stringArrayToNumbers(position),
        rotation: stringArrayToNumbers(rotation),
        shape: shapeFromCrop(stringArrayToNumbers(crop), width, height),
        scale,
      };

      return acc;
    },
    {}
  );

  const result = {
    ...mergedSettings,
    position: stringArrayToNumbers(mergedSettings.position),
    surfaces: convertedSurfaces,
  };

  return result;
}

export default class SwitchingStreamScreen {
  constructor(scene, camera, screenId, builder) {
    this.scene = scene;
    this.camera = camera;
    this.listener = null;
    this.builder = builder;
    this.screenId = screenId;

    this.streamScreen = null;
    this.audioListener = null;

    this.defaultSceneSettings = null;
    this.sceneSettings = null;
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("screens")
      .doc(this.screenId)
      .onSnapshot((doc) => {
        const { active, scene, defaults } = doc.data();

        this.defaultSceneSettings = defaults;

        this.setActive(active);

        if (this.sceneSubscription) {
          // unsusbscribing from scene changes
          this.sceneSubscription();
        }

        this.sceneSubscription = db
          .collection("screens")
          .doc(this.screenId)
          .collection("scenes")
          .doc(scene)
          .onSnapshot((sceneDoc) => {
            const sceneSettings = sceneDoc.data();
            this.sceneSettings = sceneSettings;

            this.updateScreen();
          });
      });
  }

  setActive(active) {
    if (active && !this.active) {
      this.activate();
    }

    if (!active && this.activate) {
      this.deactivate();
    }

    this.active = active;
  }

  play(audioListener) {
    this.audioListener = audioListener;
    if (this.streamScreen) {
      this.streamScreen.setListener(audioListener);
    }
  }

  deactivate() {
    if (this.streamScreen) {
      this.streamScreen.remove();
      this.streamScreen = null;
    }
  }

  activate() {
    if (!this.streamScreen) {
      this.streamScreen = this.builder(this.scene, this.camera);
    }

    if (this.sceneSettings) {
      this.streamScreen.updateControls(this.sceneSettingsWithDefaults);
    }

    if (this.streamScreen && this.audioListener) {
      this.streamScreen.setListener(this.audioListener);
    }
  }

  get sceneSettingsWithDefaults() {
    return mergeSettingsWithDefaults(
      this.defaultSceneSettings,
      this.sceneSettings
    );
  }

  updateScreen() {
    if (this.streamScreen) {
      this.streamScreen.updateControls(this.sceneSettingsWithDefaults);
    } else {
      if (this.active) {
        this.activate();
      }
    }
  }

  update(raycaster) {
    if (this.streamScreen) this.streamScreen.updatePlayStatus(raycaster);
  }
}
