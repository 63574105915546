import * as THREE from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";

const SPEED = 50;

class PlayerControls {
  // eslint-disable-next-line
  constructor() {
    // initialize empty object as we will attach three.js scene elements to it in the scene.
  }

  // Set up pointer lock controls and corresponding event listeners
  setup(camera, domElement, raycaster) {
    this.camera = camera;
    this.domElement = domElement;
    this.raycaster = raycaster;
    const jumpSpeed = 12;
    this.pointerControls = new PointerLockControls(
      this.camera,
      this.domElement
    );
    this.paused = true;

    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.canJump = false;

    this.prevTime = performance.now();
    this.velocity = new THREE.Vector3();
    this.direction = new THREE.Vector3();
    this.vertex = new THREE.Vector3();
    this.color = new THREE.Color();

    // const overlay = document.getElementById("overlay");

    this.pointerControls.addEventListener("lock", () => {
      this.clear();
      this.paused = false;
      // overlay.style.visibility = "hidden";
      // document.getElementById("instructions-overlay").style.visibility =
      // "visible";
      document.getElementById("current-instructions").innerText = "Esc to Exit";
    });

    this.pointerControls.addEventListener("unlock", () => {
      // overlay.style.visibility = "visible";
      this.clear();
      this.paused = true;
      // document.getElementById("instructions-overlay").style.visibility =
      // "hidden";
      document.getElementById("current-instructions").innerText =
        "Click to Enter";
    });

    document.addEventListener(
      "keydown",
      (event) => {
        switch (event.keyCode) {
          case 38: // up
          case 87: // w
            this.moveForward = true;
            break;

          case 37: // left
          case 65: // a
            this.moveLeft = true;
            break;

          case 40: // down
          case 83: // s
            this.moveBackward = true;
            break;

          case 39: // right
          case 68: // d
            this.moveRight = true;
            break;

          case 32: // space
            if (this.canJump === true) this.velocity.y = jumpSpeed;
            this.canJump = false;
            break;

          default:
            break;
        }
      },
      false
    );

    document.addEventListener(
      "keyup",
      (event) => {
        switch (event.keyCode) {
          case 38: // up
          case 87: // w
            this.moveForward = false;
            break;

          case 37: // left
          case 65: // a
            this.moveLeft = false;
            break;

          case 40: // down
          case 83: // s
            this.moveBackward = false;
            break;

          case 39: // right
          case 68: // d
            this.moveRight = false;
            break;
          default:
            break;
        }
      },
      false
    );

    this.velocity.y = 0;
  }

  // clear control state every time we reenter the game
  clear() {
    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.canJump = false;
    this.velocity.x = 0;
    this.velocity.z = 0;
    this.velocity.y = 0;
  }

  clonePosition() {
    return this.pointerControls.getObject().position.clone();
  }

  lock() {
    this.pointerControls.lock();
  }

  unlock() {
    this.pointerControls.unlock();
  }

  // update for these controls, which are unfortunately not included in the controls directly...
  // see: https://github.com/mrdoob/three.js/issues/5566
  update(obstacles, cameraHeight, checkCollisions) {
    if (this.pointerControls.isLocked === true) {
      const origin = this.clonePosition();
      origin.y -= cameraHeight; // origin is at floor level

      this.raycaster.set(origin, new THREE.Vector3(0, -cameraHeight, 0));

      const onObject = checkCollisions(this.raycaster, 0.1);

      const time = performance.now();
      const rawDelta = (time - this.prevTime) / 1000;
      // clamp delta so lower frame rate clients don't end up way far away
      const delta = Math.min(rawDelta, 0.1);

      this.velocity.x -= this.velocity.x * 10.0 * delta;
      this.velocity.z -= this.velocity.z * 10.0 * delta;

      this.velocity.y -= 9.8 * 8.0 * delta; // 100.0 = mass

      this.direction.z = Number(this.moveForward) - Number(this.moveBackward);
      this.direction.x = Number(this.moveRight) - Number(this.moveLeft);
      this.direction.normalize(); // this ensures consistent this.movements in all this.directions

      if (this.moveForward || this.moveBackward) {
        this.velocity.z -= this.direction.z * SPEED * delta;
      }

      if (this.moveLeft || this.moveRight) {
        this.velocity.x -= this.direction.x * SPEED * delta;
      }

      if (onObject === true) {
        this.velocity.y = Math.max(0, this.velocity.y);
        this.canJump = true;
      }

      if (
        (this.velocity.x > 0 && !obstacles.left) ||
        (this.velocity.x < 0 && !obstacles.right)
      ) {
        this.pointerControls.moveRight(-this.velocity.x * delta);
      }
      if (
        (this.velocity.z > 0 && !obstacles.backward) ||
        (this.velocity.z < 0 && !obstacles.forward)
      ) {
        this.pointerControls.moveForward(-this.velocity.z * delta);
      }

      this.pointerControls.getObject().position.y += this.velocity.y * delta; // new behavior

      if (this.pointerControls.getObject().position.y < cameraHeight) {
        this.velocity.y = 0;
        this.pointerControls.getObject().position.y = cameraHeight;
        this.canJump = true;
      }

      this.prevTime = time;
    }
  }
}

const playerControls = new PlayerControls();

function lockControls() {
  if (playerControls) {
    playerControls.lock();
  }
}

function unlockControls() {
  if (playerControls) {
    playerControls.unlock();
  }
}

export { playerControls, lockControls, unlockControls };
