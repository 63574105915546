import { ensureFirebaseAuth, db, getDownloadUrl } from "../db";
import { lockControls, unlockControls } from "./playerControls";
import * as THREE from "three";

const linkDepth = 0.1;
const fontColor = 0x343434;
const statusColor = 0xffffff;
const fontSize = 0.05;

const HIGHLIGHTED_COLOR = 0xffff1a;
const UNHIGHLIGHTED_COLOR = 0xffffff;

const font = new THREE.Font(
  // eslint-disable-next-line global-require
  require("modules/assets/fonts/helvetiker_bold.typeface.json")
);

const textParser = new DOMParser();

function parseText(encodedStr) {
  const dom = textParser.parseFromString(
    `<!doctype html><body>${encodedStr}`,
    "text/html"
  );
  const decodedString = dom.body.textContent;
  const dom2 = textParser.parseFromString(
    `<!doctype html><body>${decodedString}`,
    "text/html"
  );
  const decodedString2 = dom2.body.textContent;
  return decodedString2;
}

export function generateProjectModal({
  name,
  description,
  link,
  author = "",
  linkTitle,
  imagePath,
  onClose,
}) {
  const modalEl = document.createElement("div");
  modalEl.className = "project-modal";

  const contentEl = document.createElement("div");
  contentEl.className = "project-modal-content";

  const closeButton = document.createElement("button");
  closeButton.addEventListener("click", (e) => {
    e.stopPropagation();
    modalEl.remove();
    if (onClose) onClose();
  });
  closeButton.innerHTML = "X";

  const projectImageEl = document.createElement("img");
  const filename = imagePath;
  // let filename = "images/project_thumbnails/" + project.project_id + ".png";
  projectImageEl.src = filename;
  projectImageEl.className = "project-modal-img";

  const titleEl = document.createElement("h1");
  titleEl.innerHTML = parseText(name);
  titleEl.className = "project-modal-title";

  // names
  const namesEl = document.createElement("p");
  namesEl.innerHTML = `by ${author}`;
  namesEl.className = "project-modal-names";

  let descriptionEl;
  if (description) {
    descriptionEl = document.createElement("p");
    descriptionEl.innerHTML = parseText(description);
    descriptionEl.className = "project-modal-text";
  }

  let linksDiv;
  if (link) {
    linksDiv = document.createElement("div");
    linksDiv.className = "project-modal-link-container";

    const projectLinkEl = document.createElement("a");
    // projectLinkEl.href = link;
    projectLinkEl.href = link;
    projectLinkEl.innerHTML = linkTitle;
    projectLinkEl.alt = linkTitle;
    projectLinkEl.title = linkTitle;
    projectLinkEl.target = "_blank";
    projectLinkEl.rel = "noopener noreferrer";

    linksDiv.appendChild(projectLinkEl);
  }

  contentEl.appendChild(closeButton);
  contentEl.appendChild(projectImageEl);
  contentEl.appendChild(titleEl);
  contentEl.appendChild(namesEl);
  if (descriptionEl) {
    contentEl.appendChild(descriptionEl);
  }
  if (linksDiv) {
    contentEl.appendChild(linksDiv);
  }

  modalEl.appendChild(contentEl);
  document.body.appendChild(modalEl);

  unlockControls();
}

// creates a text mesh and returns it, from:
// https://threejs.org/examples/?q=text#webgl_geometry_text_shapes
function createSimpleText(message, fontColor, fontSize) {
  const mat = new THREE.LineBasicMaterial({
    color: fontColor,
    side: THREE.DoubleSide,
  });

  const shapes = font.generateShapes(message, fontSize);

  const geometry = new THREE.ShapeBufferGeometry(shapes);

  geometry.computeBoundingBox();

  const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
  const yMid = 0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);

  geometry.translate(xMid, yMid, 0);

  // make shape ( N.B. edge view not visible )
  const text = new THREE.Mesh(geometry, mat);
  return text;
}

function addLineBreak(longString) {
  const spaceIndex = longString.indexOf(" ", 10);
  if (spaceIndex !== -1) {
    const firstHalf = longString.slice(0, spaceIndex);
    let secondHalf = longString.slice(spaceIndex, longString.length);
    if (secondHalf.length > 15) {
      secondHalf = addLineBreak(secondHalf);
    }
    return `${firstHalf.trim()}\n${secondHalf.trim()}`;
  }
  return longString;
}

function createPodiumMesh(textureLoader, position, imageFilePath, projectName) {
  const geometry = new THREE.BoxGeometry(linkDepth, 0.75, 0.75);
  const textBoxGeometry = new THREE.BoxGeometry(linkDepth, 0.5, 0.75);

  const textBoxMat = new THREE.MeshLambertMaterial({});

  const tex = textureLoader.load(imageFilePath);
  tex.wrapS = THREE.RepeatWrapping;
  tex.wrapT = THREE.RepeatWrapping;
  tex.repeat.set(1, 1);
  const linkMaterial = new THREE.MeshLambertMaterial({
    color: UNHIGHLIGHTED_COLOR,
    map: tex,
  });

  const textSign = new THREE.Mesh(textBoxGeometry, textBoxMat);
  const imageSign = new THREE.Mesh(geometry, linkMaterial);

  // textSign.castShadow = true;
  // imageSign.castShadow = true;

  // parse text of name and add line breaks if necessary
  let name = projectName;
  if (name.length > 15) {
    name = addLineBreak(name);
  }

  // create name text mesh
  const frontTextMesh = createSimpleText(name, fontColor, fontSize);

  const backTextMesh = frontTextMesh.clone();

  frontTextMesh.position.x += linkDepth / 2 + 0.05; // offset forward
  frontTextMesh.rotateY(Math.PI / 2);

  backTextMesh.position.x -= linkDepth / 2 + 0.05; // offset forward
  backTextMesh.rotateY(-Math.PI / 2);

  imageSign.position.set(...position);
  textSign.position.set(0, -0.75 / 2 - 0.5 / 2, 0);
  textSign.add(frontTextMesh);
  textSign.add(backTextMesh);
  imageSign.add(textSign);

  // https://stackoverflow.com/questions/24690731/three-js-3d-models-as-hyperlink/24692057
  const now = Date.now();
  imageSign.userData = {
    project: projectName,
    lastVisitedTime: now,
  };

  imageSign.name = projectName;
  return { imageSign, textSign };
}

class ProjectPodium {
  constructor(
    scene,
    textureLoader,
    {
      position,
      rotation = [0, 0, 0],
      linkTitle,
      name,
      description,
      author,
      imageBucketPath,
      link,
    }
  ) {
    this.scene = scene;
    this.textureLoader = textureLoader;

    this.name = name;
    this.description = description;
    this.author = author;
    this.linkTitle = linkTitle;
    this.position = position;
    this.rotation = rotation;
    this.imageBucketPath = imageBucketPath;
    this.imagePath = null;
    this.link = link;

    this.highlighted = false;
    this.modalOpen = false;
  }

  load() {
    this.imagePath = getDownloadUrl(this.imageBucketPath);

    const { imageSign, textSign } = createPodiumMesh(
      this.textureLoader,
      this.position,
      this.imagePath,
      this.name
    );

    imageSign.position.set(...this.position);
    imageSign.rotation.set(...this.rotation);

    this.scene.add(imageSign);
    // this.scene.add(textSign);

    this.imageSign = imageSign;
    this.textSign = textSign;
  }

  remove() {
    this.scene.remove(this.imageSign);
    this.imageSign.material.dispose();
    this.imageSign.geometry.dispose();
    this.imageSign = null;

    this.scene.remove(this.textSign);
    this.textSign.material.dispose();
    this.textSign.geometry.dispose();
    this.textSign = null;
  }

  highlight() {
    if (this.highlighted) return;

    this.textSign.material.color.set(HIGHLIGHTED_COLOR);

    this.highlighted = true;
  }

  unhighlight() {
    if (!this.highlighted) return;

    this.textSign.material.color.set(UNHIGHLIGHTED_COLOR);

    this.highlighted = false;
  }

  displayModalIfActive() {
    if (this.highlighted && !this.modalOpen) {
      generateProjectModal({
        name: this.name,
        description: this.description,
        link: this.link,
        linkTitle: this.linkTitle,
        author: this.author,
        imagePath: this.imagePath,
        onClose: () => {
          lockControls();
          this.modalOpen = false;
        },
      });

      this.modalOpen = true;
    }
  }

  update(raycaster) {
    if (
      raycaster.intersectObjects([this.imageSign, this.textSign]).length > 0
    ) {
      this.highlight();
    } else {
      this.unhighlight();
    }
  }
}

function parsePodiumConfig(config) {
  return {
    ...config,
    position: config.position.split(",").map((x) => +x),
    rotation: config.rotation
      ? config.rotation.split(",").map((x) => +x)
      : [0, 0, 0],
  };
}

export default class ProjectPodiums {
  constructor(scene) {
    this.scene = scene;

    this.podiumConfigs = [];

    this.podiums = [];

    this.textureLoader = new THREE.TextureLoader();

    this.podiumsActive = false;
  }

  async load() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("elements")
      .onSnapshot((doc) => {
        const podiumsActive = doc.data().podiumsActive;

        if (podiumsActive !== this.podiumsActive) {
          this.podiumsActive = podiumsActive;
          this.updatePodiums();
        }
      });

    db.collection("projectPodiums").onSnapshot((querySnapshot) => {
      let podiumConfigs = [];

      querySnapshot.forEach((doc) => {
        podiumConfigs.push(parsePodiumConfig(doc.data()));
      });

      this.podiumConfigs = podiumConfigs;

      this.updatePodiums();
    });

    document.addEventListener("click", () => {
      this.displayModalOnActivePodium();
    });
  }

  displayModalOnActivePodium() {
    this.podiums.forEach((podium) => {
      podium.displayModalIfActive();
    });
  }

  updatePodiums() {
    this.podiums.forEach((podium) => podium.remove());

    if (!this.podiumsActive) {
      this.podiums = [];
      return;
    }

    const activeConfigs = this.podiumConfigs.filter(({ active }) => active);

    this.podiums = activeConfigs.map((podiumConfig) => {
      const podium = new ProjectPodium(
        this.scene,
        this.textureLoader,
        podiumConfig
      );
      podium.load();

      return podium;
    });
  }

  update(raycaster) {
    const originalFar = raycaster.far;
    raycaster.far = 5;

    this.podiums.forEach((podium) => podium.update(raycaster));

    raycaster.far = originalFar;
  }
}
