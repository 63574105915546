/* eslint-disable global-require */
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import gsap from "gsap";
import RemoteControlledBackground from "modules/background";
import { db, ensureFirebaseAuth } from "../../db";

export function loadModel(
  gLTFLoader,
  file,
  material,
  scale,
  castShadow,
  receiveShadow,
  position = [0, 0, 0]
) {
  return new Promise((resolve, reject) => {
    gLTFLoader.load(
      file,
      (gltf) => {
        const { scene, animations } = gltf;
        scene.position.set(...position);
        scene.scale.set(scale, scale, scale);
        const children = [];
        scene.name = file.slice(11, file.indexOf("."));
        scene.traverse((child) => {
          if (child.isMesh) {
            if (material) {
              child.material = material;
            }
            child.castShadow = castShadow;
            child.receiveShadow = receiveShadow;
            children.push(child);
          }
        });
        resolve({ scene, children, animations });
      },
      undefined,
      (e) => {
        reject(e);
        console.error(e);
      }
    );
  });
}

class Lights {
  constructor(scene) {
    // add some lights
    const ambientLight = new THREE.AmbientLight(0xffffe6, 0.7);
    scene.add(ambientLight);
    this.ambientLight = ambientLight;

    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_lights_hemisphere.html
    // main sunlight with shadows
    const mainSunglightWithShadows = new THREE.DirectionalLight(0xffffe6, 0.7);
    mainSunglightWithShadows.color.setHSL(0.1, 1, 0.95);
    mainSunglightWithShadows.position.set(-1, 0.5, -1);
    mainSunglightWithShadows.position.multiplyScalar(200);
    scene.add(mainSunglightWithShadows);
    this.mainSunglightWithShadows = mainSunglightWithShadows;

    mainSunglightWithShadows.castShadow = true;
    mainSunglightWithShadows.shadow.mapSize.width = 1024;
    mainSunglightWithShadows.shadow.mapSize.height = 1024;

    const d = 150;
    mainSunglightWithShadows.shadow.camera.left = -d;
    mainSunglightWithShadows.shadow.camera.right = d;
    mainSunglightWithShadows.shadow.camera.top = d;
    mainSunglightWithShadows.shadow.camera.bottom = -d;

    mainSunglightWithShadows.shadow.camera.far = 3500;
    mainSunglightWithShadows.shadow.bias = -0.0001;

    // secondary directional light without shadows:
    const secondaryLight = new THREE.DirectionalLight(0xffffff, 0.5);
    secondaryLight.color.setHSL(0.1, 1, 0.95);
    secondaryLight.position.set(1, 0.5, -1);
    secondaryLight.position.multiplyScalar(200);
    scene.add(secondaryLight);
    this.secondaryLight = secondaryLight;
  }

  updateLighting({
    ambientLightIntensity,
    mainSunlightIntensity,
    secondaryLightIntensity,
  }) {
    if (ambientLightIntensity !== this.ambientLight.intensity)
      if (this.hasTransitioned) {
        gsap.to(this.ambientLight, {
          duration: 1,
          intensity: ambientLightIntensity,
        });
      } else {
        this.ambientLight.intensity = ambientLightIntensity;
      }

    if (mainSunlightIntensity !== this.ambientLightIntensity)
      if (this.hasTransitioned) {
        gsap.to(this.mainSunglightWithShadows, {
          duration: 1,
          intensity: mainSunlightIntensity,
        });
      } else {
        this.mainSunglightWithShadows.intensity = mainSunlightIntensity;
      }

    if (secondaryLightIntensity !== this.secondaryLightIntensity) {
      if (this.hasTransitioned) {
        gsap.to(this.secondaryLight, {
          duration: 1,
          intensity: secondaryLightIntensity,
        });
      } else {
        this.secondaryLight.intensity = secondaryLightIntensity;
      }
    }

    this.hasTransitioned = true;
  }
}

class RemoteControlledLights {
  constructor(scene) {
    this.lights = new Lights(scene);

    this.susbcribeToChanges();
  }

  async susbcribeToChanges() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("lights")
      .onSnapshot((doc) => {
        this.lights.updateLighting(doc.data());
      });
  }
}

const SCALE_FACTOR = 1.25;

export default class Environment {
  constructor(scene) {
    this.scene = scene;

    this.collidableMeshLists = [];
  }

  setup() {
    this.addLights();

    this.loadBackground();
    this.createMaterials();
    this.loadFloorModel();

    this.susbcribeToChanges();
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Lighting 💡
  addLights() {
    this.lights = new RemoteControlledLights(this.scene);
  }

  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  //= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
  // Model 🏗
  loadBackground() {
    this.background = new RemoteControlledBackground(this.scene);
    this.background.load();
  }

  // this method instantiates materials for various parts of the ITP floor model
  // wall, ceiling, floor
  createMaterials() {
    this.testMaterial = new THREE.MeshLambertMaterial({ color: 0xffff1a });

    this.statusBoxMaterial = new THREE.MeshLambertMaterial({ color: 0xff0000 });

    // wall material:
    this.wallMaterial = new THREE.MeshLambertMaterial({
      color: 0xffffe6,
    });

    // ceiling material
    this.ceilingMaterial = new THREE.MeshLambertMaterial({ color: 0xffffff });

    // floor material
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_materials_variations_phong.html
    const floorTexture = new THREE.TextureLoader().load(
      require("./textures/floor.jpg")
    );
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    this.floorMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      map: floorTexture,
    });

    this.paintedMetalMaterial = new THREE.MeshLambertMaterial({
      color: 0x1a1a1a,
      flatShading: true,
    });

    this.windowShelfMaterial = new THREE.MeshLambertMaterial({
      color: 0x565656,
    });

    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_materials_physical_transparency.html
    this.glassMaterial = new THREE.MeshPhongMaterial({
      color: 0xd9ecff,
      transparent: true,
      opacity: 0.25,
      refractionRatio: 0.8,
    });

    this.lightHousingMaterial = new THREE.MeshLambertMaterial({
      color: 0x111111,
    });

    this.lightDiffuserMaterial = new THREE.MeshLambertMaterial({
      color: 0xcccccc,
    });

    this.glassFixturingMaterial = new THREE.MeshLambertMaterial({
      color: 0x000000,
    });
    this.graniteBarMaterial = new THREE.MeshLambertMaterial({
      color: 0x000000,
    });
  }

  async susbcribeToChanges() {
    await ensureFirebaseAuth();

    db.collection("environment")
      .doc("model")
      .onSnapshot((doc) => {
        this.partsConfig = doc.data().parts;

        // console.log("parts config", doc.data(), this.partsConfig);

        this.updateShownParts();
      });
  }

  async loadFloorModel() {
    const gLTFLoader = new GLTFLoader();
    this.matMode = 0;

    const partConfigs = [
      {
        file: require("./models/itp/ceiling.glb"),
        material: this.ceilingMaterial,
        castShadow: true,
        receiveShadow: false,
      },
      {
        file: require("./models/itp/floor.glb"),
        material: this.floorMaterial,
        castShadow: false,
        receiveShadow: true,
        collidable: true,
      },
      {
        file: require("./models/itp/glass-fixturing.glb"),
        material: this.glassFixturingMaterial,
        castShadow: true,
        receiveShadow: false,
      },
      {
        file: require("./models/itp/glass.glb"),
        material: this.glassMaterial,
        castShadow: false,
        receiveShadow: false,
        collidable: true,
      },
      {
        file: require("./models/itp/ibeam.glb"),
        material: this.paintedMetalMaterial,
        castShadow: true,
        receiveShadow: false,
        collidable: true,
      },
      {
        file: require("./models/itp/walls.glb"),
        material: this.wallMaterial,
        castShadow: true,
        receiveShadow: false,
        collidable: true,
      },
      {
        file: require("./models/itp/window-shelf.glb"),
        material: this.windowShelfMaterial,
        castShadow: true,
        receiveShadow: false,
      },
      {
        file: require("./models/itp/wooden-bar.glb"),
        material: this.floorMaterial,
        castShadow: true,
        receiveShadow: true,
        collidable: true,
      },
    ];

    this.parts = await Promise.all(
      partConfigs.map(
        async ({ file, material, castShadow, receiveShadow, collidable }) => {
          const { scene, children } = await loadModel(
            gLTFLoader,
            file,
            material,
            SCALE_FACTOR,
            castShadow,
            receiveShadow
          );

          const name = file.slice(file.lastIndexOf("/") + 1, file.indexOf("."));

          this.scene.add(scene);

          return { scene, children, collidable, name };
        }
      )
    );

    this.updateShownParts();

    //   console.log(`Loading floor part: '${name}'`);
    //   scene.name = name;

    //   this.scene.add(scene);
    //   this.floorModelParts.push(scene);

    //   if (collidable) {
    //     children.forEach((child) => this.collidableMeshList.push(child));
    //   }
    // });
  }

  shouldShowPart(partName) {
    // console.log('should show', this.partsConfig);
    if (!this.partsConfig) return true;

    if (this.partsConfig[partName] === false) return false;

    return true;
  }

  updateShownParts() {
    if (!this.parts) return;

    this.collidableMeshLists = [];

    for (let part of this.parts) {
      if (this.shouldShowPart(part.name)) {
        this.collidableMeshLists.push(part.children);
        if (!part.shown) {
          part.scene.visible = true;
          part.shown = true;
        }
      } else {
        if (part.shown) {
          part.scene.visible = false;
          part.shown = false;
        }
      }
    }
  }

  getMatFromName(name) {
    let mat = null;
    switch (name) {
      case "ceiling":
        mat = this.ceilingMaterial;
        break;
      case "floor":
        mat = this.floorMaterial;
        break;
      case "glass-fixturing":
        mat = this.glassFixturingMaterial;
        break;
      case "glass":
        mat = this.glassMaterial;
        break;
      case "granite-bar":
        mat = this.graniteBarMaterial;
        break;
      case "ibeam":
        mat = this.paintedMetalMaterial;
        break;
      case "light-diffuser":
        mat = this.lightDiffuserMaterial;
        break;
      case "light-housing":
        mat = this.lightHousingMaterial;
        break;
      case "lighting-grid":
        mat = this.wallMaterial;
        break;
      case "walls":
        mat = this.wallMaterial;
        break;
      case "window-shelf":
        mat = this.windowShelfMaterial;
        break;
      case "wooden-bar":
        mat = this.floorMaterial;
        break;
      default:
        break;
    }

    return mat;
  }

  swapMaterials() {
    this.matMode++;
    if (this.matMode >= 3) {
      this.matMode = 0;
    }
    switch (this.matMode) {
      case 0:
        this.parts.forEach(({ scene, name }) => {
          const mat = this.getMatFromName(name);
          scene.traverse((child) => {
            if (child.isMesh) {
              child.material = mat;
            }
          });
        });
        break;

      case 1:
        this.parts.forEach(({ scene, name }) => {
          if (name === "floor" || name === "glass") {
            return;
          }

          scene.traverse((child) => {
            if (child.isMesh) {
              // https://stackoverflow.com/questions/43088424/setting-random-color-for-each-face-in-threejs-results-in-black-object
              const col = new THREE.Color(0xffffff);
              col.setHex(Math.random() * 0xffffff);
              const mat = new THREE.MeshLambertMaterial({ color: col });
              child.material = mat;
            }
          });
        });
        break;

      case 2:
        this.parts.forEach(({ scene, name }) => {
          if (name === "floor" || name === "glass") {
            return;
          }

          scene.traverse((child) => {
            if (child.isMesh) {
              // https://stackoverflow.com/questions/43088424/setting-random-color-for-each-face-in-threejs-results-in-black-object
              const col = new THREE.Color(0xffffff);
              col.setHex(Math.random() * 0xffffff);
              const mat = new THREE.MeshPhongMaterial({
                color: col,
                reflectivity: 0.4,
                shininess: 1,
                envMap: this.background.envMap,
              });
              child.material = mat;
            }
          });
        });

        break;
      default:
        break;
    }
  }

  checkCollisions(raycaster, detectCollisionDistance) {
    for (let collidableMeshList of this.collidableMeshLists) {
      const collisions = raycaster.intersectObjects(collidableMeshList);

      if (
        collisions.length > 0 &&
        collisions[0].distance < detectCollisionDistance
      ) {
        return true;
      }
    }
    return false;
  }
}
