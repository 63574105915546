/* eslint-disable no-unused-vars */
/*
 * YORB 2020
 *
 * Aidan Nelson, April 2020
 *
 */

//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// IMPORTS
//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

import randomString from "random-string";
import UrlParse from "url-parse";
import { createSelfView, createMiniMap } from "./modules/sketches";
import Scene from "./scene";
import { gameState } from "./gameState";
import { webcamState } from "./webcamState";
import RoomClient from "./libs/RoomClient";
import * as cookiesManager from "./libs/cookiesManager";
import deviceInfo from "./libs/deviceInfo";
import Logger from "./libs/Logger";

const logger = new Logger();

//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Setup Global Variables:
//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

let yorbScene;
let roomClient;
let initialized = false;

//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// Start-Up Sequence:
//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// start with user interaction with the DOM so we can auto-play audio/video from
// now on...
window.onload = async () => {
  console.log("Window loaded.");

  yorbScene = new Scene();

  gameState.setMaxPeersFromDB();

  // onboarding flow:
  const userMediaButton = document.getElementById("user-media-button");
  userMediaButton.addEventListener("click", () => {
    // navigator.mediaDevices.getUserMedia({
    //   audio: true,
    //   video: true,
    // });
    // createSelfView();
    // createMiniMap();
    // addRoomClient();
    advanceOnboarding();
    enterYorb();
  });

  // const startButton = document.getElementById("startButton");
  // startButton.addEventListener("click", enterYorb);
};

function advanceOnboarding() {
  document.getElementById("onboarding-modal").remove();
  // document.getElementById("onboarding-step-two").style.visibility = "visible";
}

async function enterYorb() {
  const listener = yorbScene.initializeAudio();
  yorbScene.controls.lock();
  yorbScene.playElements(listener);
  document.getElementById("instructions-overlay").style.visibility = "visible";
  document.getElementById("onboarding-step-two").style.visibility = "hidden";
  document.getElementById("onboarding-modal").style.visibility = "hidden";
  // const onboardingModal = document.getElementById("onboarding-modal");
  // if (onboardingModal){
  //   onboardingModal.remove();
  // }

  // only join room after we user has interacted with DOM (to ensure that media elements play)
  if (!initialized) {
    const sceneContainer = document.getElementById("instructions-overlay");
    sceneContainer.addEventListener("click", enterYorb);
    // roomClient.join();
    // setupControls();
    initialized = true;
    console.log("Joined");
  }
}

//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//
// User Interface 🚂
//= =//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//==//

// function setupControls() {
//   window.addEventListener(
//     "keyup",
//     async (e) => {
//       if (e.keyCode === 67) {
//         // "C"
//         await roomClient.toggleWebcamVideoPauseState();
//         updateWebcamIcon();
//       }
//       if (e.keyCode === 77) {
//         // "M"
//         await roomClient.toggleMicrophoneAudioPauseState();
//         updateMicrophoneIcon();
//       }
//       if (e.keyCode === 49) {
//         // "1"
//         yorbScene.swapMaterials();
//       }
//       if (e.keyCode === 80) {
//         // 'p'
//         console.log(gameState.playerPosition);
//       }
//     },
//     false
//   );
// }

// function updateWebcamIcon() {
//   const webcamImage = document.getElementById("webcam-status-image");
//   if (webcamState.videoPaused) {
//     webcamImage.src = require("./modules/assets/images/no-webcam.png");
//   } else {
//     webcamImage.src = require("./modules/assets/images/webcam.png");
//   }
// }

// function updateMicrophoneIcon() {
//   const micImg = document.getElementById("microphone-status-image");
//   if (webcamState.audioPaused) {
//     micImg.src = require("./modules/assets/images/no-mic.png");
//   } else {
//     micImg.src = require("./modules/assets/images/mic.png");
//   }
// }

// function addRoomClient() {
//   const urlParser = new UrlParse(window.location.href, true);
//   const peerId = randomString({ length: 8 }).toLowerCase();
//   let roomId = urlParser.query.roomId;
//   let displayName =
//     urlParser.query.displayName || (cookiesManager.getUser() || {}).displayName;
//   const handler = urlParser.query.handler;
//   const useSimulcast = urlParser.query.simulcast !== "false";
//   const useSharingSimulcast = urlParser.query.sharingSimulcast !== "false";
//   const forceTcp = urlParser.query.forceTcp === "true";
//   const produce = urlParser.query.produce !== "false";
//   const consume = urlParser.query.consume !== "false";
//   const forceH264 = urlParser.query.forceH264 === "true";
//   const forceVP9 = urlParser.query.forceVP9 === "true";
//   const svc = urlParser.query.svc;
//   const datachannel = urlParser.query.datachannel !== "false";
//   const info = urlParser.query.info === "true";
//   const externalVideo = urlParser.query.externalVideo === "true";
//   const throttleSecret = urlParser.query.throttleSecret;

//   if (info) {
//     // eslint-disable-next-line require-atomic-updates
//     window.SHOW_INFO = true;
//   }

//   if (throttleSecret) {
//     // eslint-disable-next-line require-atomic-updates
//     window.NETWORK_THROTTLE_SECRET = throttleSecret;
//   }

//   if (!roomId) {
//     roomId = randomString({ length: 8 }).toLowerCase();
//     urlParser.query.roomId = roomId;
//     // window.history.pushState("", "", urlParser.toString());
//   }

//   // Get the effective/shareable Room URL.
//   const roomUrlParser = new UrlParse(window.location.href, true);

//   for (const key of Object.keys(roomUrlParser.query)) {
//     // Don't keep some custom params.
//     switch (key) {
//       case "roomId":
//       case "handler":
//       case "simulcast":
//       case "sharingSimulcast":
//       case "produce":
//       case "consume":
//       case "forceH264":
//       case "forceVP9":
//       case "forceTcp":
//       case "svc":
//       case "datachannel":
//       case "info":
//       case "faceDetection":
//       case "externalVideo":
//       case "throttleSecret":
//         break;
//       default:
//         delete roomUrlParser.query[key];
//     }
//   }
//   delete roomUrlParser.hash;

//   let displayNameSet;

//   // If displayName was provided via URL or Cookie, we are done.
//   if (displayName) {
//     displayNameSet = true;
//   }
//   // Otherwise pick a random name and mark as "not set".
//   else {
//     displayNameSet = false;
//     // displayName = randomName();
//     displayName = "Abalone";
//   }

//   // Get current device info.
//   const device = deviceInfo();

//   roomClient = new RoomClient({
//     roomId,
//     peerId,
//     displayName,
//     device,
//     handlerName: handler,
//     useSimulcast,
//     useSharingSimulcast,
//     forceTcp,
//     produce,
//     consume,
//     forceH264,
//     forceVP9,
//     svc,
//     datachannel,
//     externalVideo,
//   });

//   // NOTE: For debugging.
//   window.CLIENT = roomClient; // eslint-disable-line require-atomic-updates
//   window.CC = roomClient; // eslint-disable-line require-atomic-updates
// }
