import MidiExtractor from "./midiExtractor";
import SoundObjects from "./soundObjects";
import StreamScreen from "modules/streaming/streamScreen";

class ThreeDeeSoundStream extends StreamScreen {
  createSound() {
    this.soundObjects = new SoundObjects(
      this.pivot,
      this.camera,
      this.videoElement,
      this.soundConfig
    );

    return this.soundObjects;
  }

  updateControls(controls) {
    super.updateControls(controls);

    const { pixelSize, pixelFields, sound } = controls;

    this.soundConfig = sound;

    this.createMidiExtractor(this.videoElement, pixelSize, pixelFields);
  }

  createMidiExtractor(videoElement, pixelSize, pixelFields) {
    if (this.midiExtractor) {
      this.midiExtractor.remove();
    }

    this.midiExtractor = new MidiExtractor(
      videoElement,
      pixelSize,
      pixelFields
    );
  }

  animate() {
    const midiValues = this.midiExtractor.getValues();

    this.soundObjects.update(midiValues);

    this.animationFrame = window.requestAnimationFrame(() => this.animate());
  }

  remove() {
    super.remove();

    if (this.midiExtractor) {
      this.midiExtractor.remove();
    }
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  setPosition([x, y, z]) {
    this.pivot.translateX(x);
    this.pivot.translateY(y);
    this.pivot.translateZ(z);
  }
}

export function buildDansPerformance(scene, camera) {
  const streamScreen = new ThreeDeeSoundStream(scene, camera);

  return streamScreen;
}
